import React from "react";
import { Link } from "react-router-dom";
import moneydhan from "../../assets/moneydhan.png";

export const UserSidebar = () => {
  return (
    <div className="d-none d-lg-block position-relative sidebar pb-3">
      <div className="text-center" style={{ backgroundColor: "#f4f3f3" }}>
        <img src={moneydhan} alt="moneydhan-logo" style={{ width: "8rem" }} />
      </div>
      <div className="d-flex align-items-center cstm-hover border-bottom ps-3 py-2">
        <i className="fa-solid fa-coins fa-lg" style={{ color: "gold" }}></i>
        <Link
          to="/user-dashboard"
          className="d-block text-white ps-2"
          style={{ fontSize: "18px" }}
        >
          Income
        </Link>
      </div>
      <div className="d-flex align-items-center cstm-hover border-bottom ps-3 py-2">
        <i
          className="fa-solid fa-hand-holding-dollar fa-lg"
          style={{ color: "gold" }}
        ></i>
        <Link
          to="/expenses"
          className="d-block text-white ps-2"
          style={{ fontSize: "18px" }}
        >
          Expenses
        </Link>
      </div>
    </div>
  );
};
