import React, { useState, useEffect, useCallback, useRef } from "react";
import { Loader } from "../../ui/loader/Loader";
import { AdminSidebar } from "../../layout/adminSidebar";
import Footer from "../../layout/Footer";
import { AdminNavbar } from "../../layout/adminNavbar";
import "./dashboard.css";
import wave from "../../../assets/wave.png";
import yellow from "../../../assets/yellow.png";
import orange from "../../../assets/orange.png";

const AdminDashboard = () => {
  const totalApprovedClientsApi = `${process.env.REACT_APP_URL}approved-clients-count`;
  const totalClientsApi = `${process.env.REACT_APP_URL}total-clients`;
  const recentOnboardedUsersApi = `${process.env.REACT_APP_URL}new-users`;
  const recentApprovedClientsApi = `${process.env.REACT_APP_URL}approved-clients`;
  const [totalClientsCount, setTotalClientsCount] = useState(0);
  const [approvedClientsCount, setApprovedClientsCount] = useState(0);
  const [onboardedClientList, setOnboardedClientList] = useState([]);
  const [approvedClientsList, setApprovedClientsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef(false);

  const totalClients = useCallback(async () => {
    try {
      const res = await fetch(totalClientsApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      var response = await res.json();
    } catch (error) {
      console.log(error);
    }
    if (response.status === 200) {
      setTotalClientsCount(response.data);
    }
  }, []);

  const totalApprovedClients = useCallback(async () => {
    try {
      const res = await fetch(totalApprovedClientsApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      var response = await res.json();
    } catch (error) {
      console.log(error);
    }
    if (response.status === 200) {
      setApprovedClientsCount(response.data);
    }
  }, []);

  const recentOnboardedClients = useCallback(async () => {
    try {
      const res = await fetch(recentOnboardedUsersApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      var response = await res.json();
      console.log(response);
    } catch (error) {
      console.log(error);
    }
    if (response.status === 200) {
      setOnboardedClientList(response.data);
    }
  }, []);

  const recentApprovedClients = useCallback(async () => {
    try {
      const res = await fetch(recentApprovedClientsApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      var response = await res.json();
      console.log(response);
    } catch (error) {
      console.log(error);
    }
    if (response.status === 200) {
      setApprovedClientsList(response.data);
    }
  }, []);

  useEffect(() => {
    if (!ref.current) {
      setIsLoading(true);
      totalClients();
      totalApprovedClients();
      recentOnboardedClients();
      recentApprovedClients();
    }
    return () => {
      ref.current = true;
      setIsLoading(false);
    };
  }, []);

  return (
    <section className="dashboard">
      <AdminNavbar />
      <div className="row m-0">
        <div className="col-12 col-lg-2 p-0 bg-primary">
          <AdminSidebar />
        </div>
        <div className="col-12 col-lg-10 p-0 mb-4">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div className="row py-3 m-0">
                <div className="col-12 col-sm-6 col-md-4 py-2">
                  <div
                    className="card shadow p-3"
                    style={{ backgroundImage: `url(${wave})` }}
                  >
                    <p className="fs-5 fw-bold mb-0">{totalClientsCount}</p>
                    <p className="fw-bold fs-5 mb-5 text-secondary">Clients</p>
                    <i
                      className="fa-solid fa-user-tie fa-2xl icon-pos"
                      style={{ color: "#0099ff" }}
                    ></i>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 py-2">
                  <div
                    className="card shadow p-3"
                    style={{ backgroundImage: `url(${yellow})` }}
                  >
                    <p className="fs-5 fw-bold mb-0">{approvedClientsCount}</p>
                    <p className="fw-bold fs-5 mb-5 text-secondary">
                      Approved Clients
                    </p>
                    <i
                      className="fa-solid fa-person-walking-luggage fa-2xl icon-pos"
                      style={{ color: "#FFD700" }}
                    ></i>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 py-2">
                  <div
                    className=" card shadow p-3"
                    style={{ backgroundImage: `url(${orange})` }}
                  >
                    <p className="fs-5 fw-bold mb-0">50</p>
                    <p className="fw-bold fs-5 mb-5 text-secondary">
                      Renewal Clients
                    </p>
                    <i
                      className="fa-solid fa-user-clock fa-2xl icon-pos"
                      style={{ color: "#ffa500" }}
                    ></i>
                  </div>
                </div>
              </div>
              <div className="row mx-0 justify-content-between">
                <div className="col-12 col-md-6 my-2">
                  <div className="bg-white p-3 rounded-2 shadow">
                    <h5 className="fw-bold">Recently Onboarded</h5>
                    <table className="table table-striped table-bordered table-hover text-center mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Mobile</th>
                        </tr>
                      </thead>
                      <tbody>
                        {onboardedClientList.map((data) => (
                          <tr key={data.id} className="">
                            <td style={{ fontSize: "14px" }}>{data.name}</td>
                            <td style={{ fontSize: "14px" }}>{data.email}</td>
                            <td style={{ fontSize: "14px" }}>{data.mobile}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-12 col-md-6 my-2">
                  <div className="bg-white p-3 rounded-2 shadow">
                    <h5 className="fw-bold">Recently Approved</h5>
                    <table className="table table-striped table-bordered table-hover text-center mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Mobile</th>
                          <th scope="col">Pan</th>
                        </tr>
                      </thead>
                      <tbody>
                        {approvedClientsList.map((data) => (
                          <tr key={data.user_id}>
                            <td style={{ fontSize: "14px" }}>{data.name}</td>
                            <td style={{ fontSize: "14px" }}>{data.email}</td>
                            <td style={{ fontSize: "14px" }}>
                              {data.mobile_number}
                            </td>
                            <td style={{ fontSize: "14px" }}>
                              {data.pan_number}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default AdminDashboard;
