import React, { useCallback, useEffect, useState, useRef } from "react";
import Papa from "papaparse";
import { Loader } from "../../ui/loader/Loader";
import { AdminSidebar } from "../../layout/adminSidebar";
import { AdminNavbar } from "../../layout/adminNavbar";

const ViewRiskProfiles = () => {
  const showRiskProfilesApi = `${process.env.REACT_APP_URL}show-risk-profiles`;
  const [isLoading, setIsLoading] = useState(false);
  const [storeProfiles, setStoreProfiles] = useState([]);
  const ref = useRef(true);

  const AllRiskProfiles = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await fetch(showRiskProfilesApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      var data = await res.json();
    } catch (error) {
      console.log(error);
    }
    if (data.status === 200) {
      setStoreProfiles(data.data);
    } else if (data.status === 201) {
      setStoreProfiles([]);
    }
    setIsLoading(false);
  }, [showRiskProfilesApi]);

  const exportDataToCSV = (data) => {
    const { name, email, mobile, risk_score, risk_level } = data;
    const exportData = [];
    exportData.push(
      { key: "name", value: name },
      { key: "email", value: email },
      { key: "mobile", value: mobile },
      { key: "risk_score", value: risk_score },
      { key: "risk_level", value: risk_level }
    );
    Object.entries(data.question).forEach(([key, value]) => {
      exportData.push({ key, value });
    });
    const csv = Papa.unparse(exportData);
    const csvBlob = new Blob([csv], { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvBlob);
    const link = document.createElement("a");
    link.href = csvUrl;
    link.download = `${data.name}_risk_profile.csv`;
    link.click();
    URL.revokeObjectURL(csvUrl);
  };

  useEffect(() => {
    if (ref.current) {
      AllRiskProfiles();
    }
    return () => {
      ref.current = false;
    };
  }, [AllRiskProfiles]);

  let sno = 1;

  return (
    <section>
      <AdminNavbar />
      <div className="row m-0">
        <div className="col-12 col-lg-2 p-0 bg-primary">
          <AdminSidebar />
        </div>
        <div className="col-12 col-lg-10 my-5">
          <h5 className="text-primary">Risk Profiles</h5>
          {isLoading ? (
            <div className="d-flex justify-content-center mt-5">
              <Loader />
            </div>
          ) : (
            <table className="table mt-2 table-bordered table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Mobile</th>
                  <th scope="col">Risk level</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {storeProfiles.length > 0 ? (
                  storeProfiles.map((profile) => (
                    <tr key={profile.id}>
                      <td>{sno++}</td>
                      <td>{profile.name}</td>
                      <td>{profile.email}</td>
                      <td>{profile.mobile}</td>
                      <td
                        className={
                          profile.risk_level === "High risk profile"
                            ? "text-danger"
                            : profile.risk_level === "Medium risk profile"
                            ? "text-primary"
                            : "text-success"
                        }
                      >
                        {profile.risk_level}
                      </td>
                      <td>
                        <span
                          onClick={() => exportDataToCSV(profile)}
                          className="text-primary"
                          style={{ cursor: "pointer" }}
                        >
                          Download
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-center">
                    <td colSpan="6">No Risk Profile Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </section>
  );
};

export default ViewRiskProfiles;
