import React, {
  useState,
  useReducer,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteUser } from "../../../store/slices/UserSlice";
import { UserSidebar } from "../../layout/userSidebar";
import "./dashboard.css";

const UserDashboard = () => {
  const addIncomeApi = `${process.env.REACT_APP_URL}add-income`;
  const incomeListApi = `${process.env.REACT_APP_URL}income-list`;
  const [incomeListItems, setIncomeListItems] = useState([]);
  const [userName, setUserName] = useState("");
  const userData = useSelector((state) => {
    return state.users.data;
  });
  const ref = useRef(true);
  const navigate = useNavigate();
  const fnDispatch = useDispatch();
  let sno = 1;

  const initialState = {
    incomeSource: "Salaried",
    monthlyAmount: 0,
    incomeOption: "net",
    incomeStyle: "Stable",
    continuesTill: "Retirement",
    growthRate: 0,
    startDate: "",
    endDate: "",
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "update-state":
        return { ...state, [action.payload.name]: action.payload.value };
      default:
        return state;
    }
  };

  const handleInput = (e) => {
    dispatch({
      type: "update-state",
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const addIncome = async () => {
    try {
      const res = await fetch(addIncomeApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          user_id: userData.token,
          income_details: state,
        }),
      });
      var data = await res.json();
    } catch (error) {
      console.log(error.message);
    }
    if (data.status === 200) {
      incomeList();
    }
  };

  const incomeList = useCallback(async () => {
    console.log("called");
    try {
      const res = await fetch(incomeListApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          user_id: userData.token,
        }),
      });
      var data = await res.json();
    } catch (error) {
      console.log(error);
    }
    if (data.status === 200) {
      console.log(data);
      setIncomeListItems(data.data);
      setUserName(data.name);
    }
  }, [userData, incomeListApi]);

  useEffect(() => {
    if (ref.current) {
      if (!userData.token) {
        navigate("/");
      }
      incomeList();
    }
    return () => {
      ref.current = false;
    };
  }, [incomeList, userData, navigate]);

  const Logout = () => {
    fnDispatch(deleteUser());
    navigate("/");
  };
  console.log(incomeList);

  return (
    <section className="user-dashboard" style={{ height: "100vh" }}>
      <div className="row m-0 h-100">
        <div className="position-relative col-12 col-lg-2 p-0 bg-primary">
          <UserSidebar />
          <button
            className="position-absolute bottom-0 w-100 border-0 text-center p-2"
            style={{
              backgroundColor: "#f4f3f3",
              fontSize: "22px",
              cursor: "pointer",
            }}
            onClick={Logout}
          >
            <i className="bi bi-box-arrow-right me-1 text-danger"></i>
            <span>LogOut</span>
          </button>
        </div>
        <div className="col-12 col-lg-10 p-3">
          <div className="d-flex justify-content-between my-2">
            <h5>Income</h5>
            <button
              className="btn btn-primary mx-1"
              data-bs-toggle="modal"
              data-bs-target="#addIncomeModal"
            >
              ADD NEW
            </button>
          </div>
          <table className="table table-bordered talbe-striped text-center">
            <thead>
              <tr>
                <th scope="col">No.</th>
                <th scope="col">Type</th>
                <th scope="col">Monthly amount</th>
                <th scope="col">Income style</th>
                <th scope="col">Continues till</th>
                <th scope="col">Growth rate</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {incomeListItems && incomeListItems.length > 0 ? (
                incomeListItems.map((item) => (
                  <tr key={item.id}>
                    <th scope="row">{sno++}</th>
                    <td>{item.income_source}</td>
                    <td>Rs {item.monthly_amount}</td>
                    <td>{item.income_style}</td>
                    <td>{item.continues_till}</td>
                    <td>{item.growth_rate}%</td>
                    <td className="text-primary">LIVE</td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan={7}>No Income found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div
        className="modal fade"
        id="addIncomeModal"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleLabel">
                Add Income
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-2">
                <input
                  type="text"
                  value={userName}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="mb-2">
                <label className="form-label mb-0" htmlFor="incomeSource">
                  Select the applicable sources of income
                </label>
                <select
                  className="form-select"
                  name="incomeSource"
                  id="incomeSource"
                  onChange={handleInput}
                >
                  <option value="Salaried">Salaried</option>
                  <option value="Business">Business</option>
                  <option value="Profession">Profession</option>
                  <option value="Rental">Rental</option>
                  <option value="Others">Others</option>
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-primary"
                data-bs-target="#detailsModal"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="detailsModal"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleLabel2">
                Income Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="mb-2">
                    <label htmlFor="incomeOption" className="form-label mb-0">
                      Income option
                    </label>
                    <select
                      className="form-select"
                      id="incomeOption"
                      name="incomeOption"
                      onChange={handleInput}
                    >
                      <option value="net">Net (In hand)</option>
                      <option value="with breakup">With breakup</option>
                    </select>
                  </div>
                  <div className="mb-2">
                    <label htmlFor="monthlyAmount" className="form-label mb-0">
                      Monthly amount
                    </label>
                    <input
                      type="text"
                      id="monthlyAmount"
                      name="monthlyAmount"
                      className="form-control"
                      onChange={handleInput}
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="incomeStyle" className="form-label mb-0">
                      Income style
                    </label>
                    <select
                      className="form-select"
                      id="incomeStyle"
                      name="incomeStyle"
                      onChange={handleInput}
                    >
                      <option value="Stable">Stable</option>
                      <option value="Erratic">Erratic</option>
                    </select>
                  </div>
                  <div className="mb-2">
                    <label htmlFor="continuesTill" className="form-label mb-0">
                      Continues till
                    </label>
                    <select
                      className="form-select"
                      id="continuesTill"
                      name="continuesTill"
                      onChange={handleInput}
                    >
                      <option value="Retirement">Retirement</option>
                      <option value="Lifetime">Lifetime</option>
                      <option value="Number of years">Number of years</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="mb-2">
                    <label htmlFor="growthRate" className="form-label mb-0">
                      Income growth rate ( % )
                    </label>
                    <input
                      type="text"
                      id="growthRate"
                      name="growthRate"
                      className="form-control"
                      onChange={handleInput}
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="startDate" className="form-label mb-0">
                      Income period (start date)
                    </label>
                    <input
                      type="date"
                      id="startDate"
                      name="startDate"
                      className="form-control"
                      onChange={handleInput}
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="endDate" className="form-label mb-0">
                      Income period (end date)
                    </label>
                    <input
                      type="date"
                      id="endDate"
                      name="endDate"
                      className="form-control"
                      onChange={handleInput}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-danger"
                data-bs-target="#addIncomeModal"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
              >
                Back
              </button>
              <button
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={addIncome}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserDashboard;
