import React, {
  useState,
  useCallback,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser } from "../../../store/slices/UserSlice";
import { useNavigate } from "react-router-dom";
import { UserSidebar } from "../../layout/userSidebar";
import "../user-dashboard/dashboard.css";

const Expenses = () => {
  const addExpenseApi = `${process.env.REACT_APP_URL}add-expense`;
  const expenseListApi = `${process.env.REACT_APP_URL}expense-list`;
  const [expenseListItems, setExpenseListItems] = useState([]);
  const userData = useSelector((state) => {
    return state.users.data;
  });
  const ref = useRef(true);
  const navigate = useNavigate();
  const fnDispatch = useDispatch();

  const initialState = {
    expenseOption: "with breakup",
    category: "Food & Groceries",
    amount: 0,
    frequency: "Daily",
    startDate: "",
    continuesTill: "Retirement",
    paymentMode: "Credit Card",
  };
  let sno = 1;

  const reducer = (state, action) => {
    switch (action.type) {
      case "update-state":
        return { ...state, [action.payload.name]: action.payload.value };
      default:
        return state;
    }
  };

  const handleInput = (e) => {
    dispatch({
      type: "update-state",
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const addExpense = async () => {
    try {
      const res = await fetch(addExpenseApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          user_id: userData.token,
          expense_details: state,
        }),
      });
      var data = await res.json();
    } catch (error) {
      console.log(error.message);
    }
    if (data.status === 200) {
      expenseList();
    }
  };

  const expenseList = useCallback(async () => {
    try {
      const res = await fetch(expenseListApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          user_id: userData.token,
        }),
      });
      var data = await res.json();
    } catch (error) {
      console.log(error);
    }
    if (data.status === 200) {
      setExpenseListItems(data.data);
    }
  }, [userData, expenseListApi]);

  useEffect(() => {
    if (ref.current) {
      if (!userData.token) {
        navigate("/");
      }
      expenseList();
    }
    return () => {
      ref.current = false;
    };
  }, [expenseList, navigate, userData]);

  const Logout = () => {
    fnDispatch(deleteUser());
    navigate("/");
  };

  return (
    <section className="user-dashboard" style={{ height: "100vh" }}>
      <div className="row m-0 h-100">
        <div className="position-relative col-12 col-lg-2 p-0 bg-primary">
          <UserSidebar />
          <button
            className="position-absolute bottom-0 w-100 border-0 text-center p-2"
            style={{
              backgroundColor: "#f4f3f3",
              fontSize: "22px",
              cursor: "pointer",
            }}
            onClick={Logout}
          >
            <i className="bi bi-box-arrow-right me-1 text-danger"></i>
            <span>LogOut</span>
          </button>
        </div>
        <div className="col-12 col-lg-10 p-3">
          <div className="d-flex justify-content-between my-2">
            <h5>Expenses</h5>
            <button
              className="btn btn-primary mx-1"
              data-bs-toggle="modal"
              data-bs-target="#addBudgetModal"
            >
              ADD NEW
            </button>
          </div>
          <table className="table table-bordered talbe-striped text-center">
            <thead>
              <tr>
                <th scope="col">No.</th>
                <th scope="col">Category</th>
                <th scope="col">Budget</th>
                <th scope="col">Continues till</th>
                <th scope="col">Payment mode</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              {expenseListItems && expenseListItems.length > 0 ? (
                expenseListItems.map((item) => (
                  <tr key={item.id}>
                    <th scope="row">{sno++}</th>
                    <td>{item.category}</td>
                    <td>Rs. {item.amount}</td>
                    <td>{item.continues_till}</td>
                    <td>{item.payment_mode}</td>
                    <td>{item.starting_date}</td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan={6}>No Income found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div
        className="modal fade"
        id="addBudgetModal"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleLabel">
                Add Budget
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="mb-2">
                    <label htmlFor="expenseOption" className="form-label mb-0">
                      Select Expense option
                    </label>
                    <select
                      name="expenseOption"
                      id="expenseOption"
                      className="form-select"
                      onChange={handleInput}
                    >
                      <option value="with breakup">With breakup</option>
                      <option value="total monthly expense">
                        total monthly expense
                      </option>
                    </select>
                  </div>
                  <div className="mb-2">
                    <label htmlFor="category" className="form-label mb-0">
                      Select a category
                    </label>
                    <select
                      className="form-select"
                      id="category"
                      name="category"
                      onChange={handleInput}
                    >
                      <option value="Food & Groceries">Food & Groceries</option>
                      <option value="Clothing">Clothing</option>
                      <option value="Medical expenses">Medical expenses</option>
                      <option value="Shopping">Shopping</option>
                      <option value="Basic misc">Basic misc</option>
                      <option value="Iron/Laundry">Iron/Laundry</option>
                      <option value="Mobile">Mobile</option>
                      <option value="Internet">Internet</option>
                      <option value="Electricity">Electricity</option>
                      <option value="DTH">DTH</option>
                      <option value="Telephone">Telephone</option>
                      <option value="Newspaper & Magazines">
                        Newspaper & Magazines
                      </option>
                      <option value="Gas">Gas</option>
                      <option value="Daily commute">Daily commute</option>
                      <option value="Petrol/Diesel">Petrol/Diesel</option>
                      <option value="Drivers salary">Drivers salary</option>
                      <option value="Parking charges">Parking charges</option>
                      <option value="Trasnport misc">Trasnport misc</option>
                      <option value="Vehicle Maintenance">
                        Vehicle Maintenance
                      </option>
                      <option value="Scool/College/University fees">
                        Scool/College/University fees
                      </option>
                      <option value="Tution fees">Tution fees</option>
                      <option value="Books & Supplies">Books & Supplies</option>
                      <option value="Kids activites">Kids activites</option>
                      <option value="Education misc">Education misc</option>
                      <option value="Rent">Rent</option>
                      <option value="Society Maintenance">
                        Society Maintenance
                      </option>
                      <option value="Car wash">Car wash</option>
                      <option value="Housing misc">Housing misc</option>
                      <option value="Property tax">Property tax</option>
                      <option value="Maid/Domestic helper">
                        Maid/Domestic helper
                      </option>
                      <option value="Movies">Movies</option>
                      <option value="Restaurants">Restaurants</option>
                      <option value="Amusements">Amusements</option>
                      <option value="Vacation">Vacation</option>
                      <option value="Entertainment misc">
                        Entertainment misc
                      </option>
                      <option value="Gifts & Donations">
                        Gifts & Donations
                      </option>
                      <option value="Personal care">Personal care</option>
                      <option value="Health & Fitness">Health & Fitness</option>
                      <option value="Doctor">Doctor</option>
                      <option value="Dentist">Dentist</option>
                      <option value="Miscellaneous">Miscellaneous</option>
                      <option value="Professional Fees">
                        Professional Fees
                      </option>
                      <option value="Repairs & Maintenance">
                        Repairs & Maintenance
                      </option>
                      <option value="Nanny - Baby sitting">
                        Nanny - Baby sitting
                      </option>
                      <option value="Salon & Spa">Salon & Spa</option>
                      <option value="Parental Care">Parental Care</option>
                      <option value="Contribution to parents/siblings">
                        Contribution to parents/siblings
                      </option>
                    </select>
                  </div>
                  <div className="mb-2">
                    <label htmlFor="amount" className="form-label mb-0">
                      Enter Amount
                    </label>
                    <input
                      type="text"
                      id="amount"
                      name="amount"
                      className="form-control"
                      onChange={handleInput}
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="frequency" className="form-label mb-0">
                      Frequency
                    </label>
                    <select
                      id="frequency"
                      name="frequency"
                      className="form-select"
                      onChange={handleInput}
                    >
                      <option value="Daily">Daily</option>
                      <option value="Weekly">Weekly</option>
                      <option value="Monthly">Monthly</option>
                      <option value="Yearly">Yearly</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="mb-2">
                    <label htmlFor="startDate" className="form-label mb-0">
                      Start from
                    </label>
                    <input
                      type="date"
                      id="startDate"
                      name="startDate"
                      className="form-control"
                      onChange={handleInput}
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="continuesTill" className="form-label mb-0">
                      Continues till
                    </label>
                    <select
                      id="continuesTill"
                      name="continuesTill"
                      className="form-select"
                      onChange={handleInput}
                    >
                      <option value="Retirement">Retirement</option>
                      <option value="Life time">Life time</option>
                      <option value="No. of years">No. of years</option>
                      <option value="No. of times">No. of times</option>
                    </select>
                  </div>
                  <div className="mb-2">
                    <label htmlFor="paymentMode" className="form-label mb-0">
                      Payment mode
                    </label>
                    <select
                      id="paymentMode"
                      name="paymentMode"
                      className="form-select"
                      onChange={handleInput}
                    >
                      <option value="Credit card">Credit card</option>
                      <option value="Debit card">Debit card</option>
                      <option value="Cash">Cash</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                onClick={addExpense}
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Expenses;
