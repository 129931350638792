import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./registration.css";
import signupImg from "../../../assets/1new.png";
import { addUser } from "../../../store/slices/UserSlice";
import Error from "../../ui/Error";

const Signup = () => {
  const [phone, setPhone] = useState("");
  const [newOtp, setNewOtp] = useState("");
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sendOtpApi = `${process.env.REACT_APP_URL}sendOtp`;
  const otpVerifyApi = `${process.env.REACT_APP_URL}verifyOtp`;
  const registrationApi = `${process.env.REACT_APP_URL}registration`;

  const sendOtp = async () => {
    if (phone.length === 10 && phone.match(/^[0-9]+$/)) {
      try {
        const res = await fetch(sendOtpApi, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            number: phone,
          }),
        });
        var data = await res.json();
      } catch (error) {
        alert("Sorry, something went wrong " + error.message);
      }
      if (data.status === 500) {
        document.getElementById("numberErrorText").classList.remove("d-none");
      } else {
        document.getElementById("numberField").classList.add("d-none");
        document.getElementById("otpField").classList.remove("d-none");
        document.getElementById("otpBtn").classList.add("d-none");
        document.getElementById("submitOtpBtn").classList.remove("d-none");
      }
    } else {
      document.getElementById("errorText").classList.remove("d-none");
    }
  };

  const otpVerification = async () => {
    if (newOtp.trim() !== "" && newOtp !== null) {
      try {
        var res = await fetch(otpVerifyApi, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            number: phone,
            otp: newOtp,
          }),
        });
        var data = await res.json();
      } catch (error) {
        alert("Sorry, something went wrong " + error.message);
      }
      if (data.status == 200) {
        document.getElementById("otpCard").classList.add("d-none");
        document.getElementById("details-card").classList.remove("d-none");
      }
      if (data.status == 500) {
        document.getElementById("wrongOtpText").classList.remove("d-none");
        // document.getElementById("resendOtpText").classList.add("d-none");
        // document.getElementById("otpSuccessText").classList.add("d-none");
      }
    }
  };

  // const resendOtp = () => {
  //   // document.getElementById("otpSuccessText").classList.add("d-none");
  //   document.getElementById("wrongOtpText").classList.add("d-none");
  //   sendOtp();
  //   // document.getElementById("resendOtpText").classList.remove("d-none");
  // };

  const updateUserDetails = (e) => {
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const registerApi = async (e) => {
    document.getElementById("user-exists-text").classList.add("d-none");
    e.preventDefault();
    try {
      var res = await fetch(registrationApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          name: user.name,
          email: user.email,
          password: user.password,
          mobile: phone,
        }),
      });
      var data = await res.json();
    } catch (error) {
      setIsError(true);
    }
    if (data.status === 200) {
      dispatch(addUser({ token: data.token, role: 0, step: 1 }));
      navigate("/risk-profile");
    } else if (data.status === 500) {
      setIsError(false);
      document.getElementById("user-exists-text").classList.remove("d-none");
    } else {
      setIsError(true);
    }
  };

  return (
    <div className="container-fluid signup-container p-0">
      <div className="row m-0 gx-0 h-100">
        <div className="col-12 col-md-6">
          <div className="left-container">
            <div className="logo text-center text-md-start">
              <img
                src="https://moneydhan.com/wp-content/uploads/2021/02/Moneydhan-Logo-04-1.png"
                alt="moneydhan-logo"
              />
            </div>
            <div className="d-none d-md-block text-center mt-5">
              <img
                src={signupImg}
                alt="signup-hero-img"
                className="cstm-img-width"
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div
            className="right-container order-2 h-100 d-flex flex-column justify-content-center align-items-center"
            style={{ backgroundColor: "#3861c0" }}
          >
            <div className="form-container bg-white p-2 p-sm-3 p-lg-5">
              <div className="card-body p-0" id="otpCard">
                <div className="form-group mb-3 number-form" id="numberField">
                  <label className="mb-0">Your Mobile number*</label>
                  <div className="d-flex align-items-center position-relative border border-1 input-area">
                    <input
                      className="form-control bg-transparent input-field border-0"
                      type="text"
                      name="number"
                      id="mobile"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <i className="bi bi-telephone-fill position-absolute fs-5 email-icon text-secondary"></i>
                  </div>
                  <p className="text-danger small d-none" id="errorText">
                    Please enter a valid number
                  </p>
                  <p className="text-danger small d-none" id="numberErrorText">
                    This number is already registered
                  </p>
                  <p className="text-muted small mt-2 error-text">
                    *This will be used for future communications.
                  </p>
                  <div className="mt-4">
                    <button
                      className="btn btn-primary w-100"
                      type="button"
                      id="otpBtn"
                      onClick={sendOtp}
                    >
                      Send OTP
                    </button>
                  </div>
                </div>
                <div className="form-group mb-3 d-none" id="otpField">
                  {/* <p className="text-success small mb-1" id="otpSuccessText">
                    Otp has been sent to your mobile.
                  </p>
                  <p
                    className="text-success small d-none mb-1"
                    id="resendOtpText"
                  >
                    Otp has been re-sent.
                  </p> */}
                  <label className="mb-0">Enter Passcode*</label>
                  <div className="d-flex align-items-center position-relative border border-1 input-area">
                    <input
                      className="form-control bg-transparent input-field border-0"
                      type="text"
                      name="otp"
                      id="otp"
                      value={newOtp}
                      onChange={(e) => setNewOtp(e.target.value)}
                      required
                    />
                    <i className="bi bi-lock-fill position-absolute fs-5 email-icon text-secondary"></i>
                  </div>
                  <p className="text-danger small d-none" id="wrongOtpText">
                    Wrong Passcode. Please try again.
                  </p>
                  {/* <p
                    className="text-primary text-center mt-2 mb-0"
                    style={{ cursor: "pointer" }}
                    id="resend-btn"
                    onClick={resendOtp}
                  >
                    Re-Send OTP
                  </p> */}
                  <div className="mt-4">
                    <button
                      type="button"
                      className="btn btn-primary w-100"
                      id="submitOtpBtn"
                      onClick={otpVerification}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>

              <div className="card-body p-0 d-none" id="details-card">
                <form onSubmit={registerApi}>
                  <div className="form-group mb-3">
                    <label className="mb-0">Name as per PAN/Aadhaar*</label>
                    <div className="d-flex align-items-center position-relative border border-1 input-area">
                      <input
                        className="form-control bg-transparent input-field border-0"
                        type="text"
                        id="name"
                        name="name"
                        placeholder="ex: John Smith"
                        value={user.name}
                        onChange={updateUserDetails}
                        required
                      />
                      <i className="bi bi-person-fill position-absolute fs-5 email-icon text-secondary"></i>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-0">Email*</label>
                    <div className="d-flex align-items-center position-relative border border-1 input-area">
                      <input
                        className="form-control bg-transparent input-field border-0"
                        type="email"
                        id="email"
                        name="email"
                        placeholder="ex: john@gmail.com"
                        value={user.email}
                        onChange={updateUserDetails}
                        required
                      />
                      <i className="bi bi-envelope-fill position-absolute fs-5 email-icon text-secondary"></i>
                    </div>
                    <p className="text-muted small mt-2">
                      *This will be used for future communications.
                    </p>
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-0">Create Password*</label>
                    <div className="d-flex align-items-center position-relative border border-1 input-area">
                      <input
                        className="form-control bg-transparent input-field border-0"
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Set password"
                        value={user.password}
                        onChange={updateUserDetails}
                        required
                      />
                      <i className="bi bi-lock-fill position-absolute fs-5 email-icon text-secondary"></i>
                    </div>
                  </div>
                  <p
                    className="text-danger small mb-0 d-none"
                    id="user-exists-text"
                  >
                    User is already registered. Please try and login.
                  </p>
                  {isError && <Error />}
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary w-100"
                      id="details-form-btn"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
              <p className="text-center mt-3 login-text">
                Already Have an account yet ?
                <Link className="text-primary ms-1 text-decoration-none" to="/">
                  Login
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
