import React from "react";

const Error = () => {
  return (
    <div className="text-danger small">
      Sorry, something went wrong. Please try again.
    </div>
  );
};

export default Error;
