import { useEffect, useState, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../../layout/Footer";
import { AdminSidebar } from "../../layout/adminSidebar";
import { AdminNavbar } from "../../layout/adminNavbar";
import { Loader } from "../../ui/loader/Loader";
import "./onBoarding.css";

const ClientDetails = () => {
  const location = useLocation();
  const viewClientDetialsApi = `${process.env.REACT_APP_URL}view-client-onboarding`;
  const approveClientApi = `${process.env.REACT_APP_URL}approve-client`;
  const updateUserDetailsApi = `${process.env.REACT_APP_URL}update-user-details`;

  const [userDetails, setUserDetails] = useState({});
  const [editData, setEditData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef(true);

  const viewClientDetials = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await fetch(viewClientDetialsApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          user_id: location.state.userId,
        }),
      });
      var data = await res.json();
    } catch (error) {
      console.log(error.message);
    }
    setIsLoading(false);
    if (data.status === 200) {
      setUserDetails(data.data);
      setEditData(data.data);
    }
  }, [viewClientDetialsApi, location.state.userId]);

  const updateUserDetails = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await fetch(updateUserDetailsApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          user_id: editData.id,
          name: editData.name,
          email: editData.email,
          number: editData.mobile,
        }),
      });
      var data = await res.json();
    } catch (error) {
      console.log(error.message);
    }
    setIsLoading(false);
    if (data.status !== 200) {
      alert("Sorry, something went wrong. Please try again");
    } else {
      alert("Details updated successfully");
      document.getElementById("editModal").classList.replace("show", "fade");
      viewClientDetials();
    }
  };

  const approveClient = async () => {
    try {
      const res = await fetch(approveClientApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          user_id: location.state.userId,
        }),
      });
      var data = await res.json();
    } catch (error) {
      console.log(error.message);
    }
    if (data.status === 200) {
      console.log("approved");
      viewClientDetials();
    }
  };

  useEffect(() => {
    if (ref.current) {
      viewClientDetials();
    }
    return () => {
      ref.current = false;
    };
  }, [viewClientDetials]);

  return (
    <section className="client-onboarding-container">
      <AdminNavbar />
      {isLoading ? (
        <div className="d-flex justify-content-center mt-5">
          <Loader />
        </div>
      ) : (
        <>
          <div className="row m-0">
            <div className="col-12 col-lg-2 p-0 bg-primary">
              <AdminSidebar />
            </div>
            <div className="col-12 col-lg-10">
              <div className="container w-75 bg-white m-5 p-3 rounded-3">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="d-flex align-items-center">
                    <h4 className="mb-0 me-3">{userDetails.name}</h4>
                    <p
                      className={
                        userDetails.risk_level === "High risk profile"
                          ? "text-danger mb-0"
                          : userDetails.risk_level === "Medium risk profile"
                          ? "text-primary mb-0"
                          : "text-success mb-0"
                      }
                    >
                      {userDetails.risk_level}
                      <span className="text-secondary">
                        (Score - {userDetails.risk_score})
                      </span>
                    </p>
                  </div>
                  <div>
                    {userDetails.ckyc_status === 1 ? (
                      <button
                        onClick={() => approveClient()}
                        className="disabled btn btn-outline-success me-2"
                      >
                        Approved
                      </button>
                    ) : (
                      <button
                        onClick={() => approveClient()}
                        className="btn btn-outline-primary me-2"
                      >
                        Approve as Client
                      </button>
                    )}
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#editModal"
                      className="btn btn-outline-primary"
                    >
                      Edit
                    </button>

                    <button className="d-none btn btn-outline-primary">
                      Client Renewal
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <p>
                      <span className="fw-bold">Registration Date : </span>
                      <span className="text-secondary">
                        {Object.keys(userDetails).length > 0
                          ? userDetails.created_at.slice(0, 10)
                          : null}
                      </span>
                    </p>
                    <p>
                      <span className="fw-bold">Email : </span>
                      <span className="text-secondary">
                        {userDetails.email}
                      </span>
                    </p>
                    <p>
                      <span className="fw-bold">Mobile : </span>
                      <span className="text-secondary">
                        {userDetails.mobile}
                      </span>
                    </p>
                    <p>
                      <span className="fw-bold">DOB : </span>
                      <span className="text-secondary">{userDetails.dob}</span>
                    </p>
                    <p>
                      <span className="fw-bold">PAN : </span>
                      <span className="text-secondary">
                        {userDetails.pan_number}
                      </span>
                    </p>
                    <p>
                      <span className="fw-bold">KYC Details : </span>
                      <span
                        className={
                          userDetails.ckyc_status === 1
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        {userDetails.ckyc_status === 1
                          ? "Completed"
                          : "Pending"}
                      </span>
                    </p>
                    <p>
                      <span className="fw-bold">E-Sign : </span>
                      <span
                        className={
                          userDetails.esign === 1
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        {userDetails.esign === 1 ? "Completed" : "Pending"}
                      </span>
                    </p>
                  </div>
                  <div className="col-12 col-md-6">
                    <p>
                      <span className="fw-bold">Payment : </span>
                      <span className="text-success">Completed</span>
                    </p>
                    <p>
                      <span className="fw-bold">Payment-Id : </span>
                      <span className="text-secondary">29874198371</span>
                    </p>
                    <p>
                      <span className="fw-bold">Service Type : </span>
                      <span className="text-secondary">Fixed Fee</span>
                    </p>
                    <p>
                      <span className="fw-bold">Period : </span>
                      <span className="text-secondary">1 year</span>
                    </p>
                    <p>
                      <span className="fw-bold">Fees : </span>
                      <span className="text-secondary">Rs 5,000 + gst</span>
                    </p>
                    <p>
                      <span className="fw-bold">Start Date : </span>
                      <span className="text-secondary">12-07-2023</span>
                    </p>
                    <p>
                      <span className="fw-bold">End Date : </span>
                      <span className="text-secondary">12-10-2023</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
      {editData && (
        <div
          className="modal fade"
          id="editModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" style={{ maxWidth: "700px" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Details
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <form onSubmit={updateUserDetails} className="text-start">
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label htmlFor="editName" className="form-label mb-0">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="editName"
                          value={editData.name}
                          onChange={(e) =>
                            setEditData((prev) => ({
                              ...prev,
                              name: e.target.value,
                            }))
                          }
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="editEmail" className="form-label mb-0">
                          Email address
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="editEmail"
                          value={editData.email}
                          onChange={(e) =>
                            setEditData((prev) => ({
                              ...prev,
                              email: e.target.value,
                            }))
                          }
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="editMobile" className="form-label mb-0">
                          Mobile
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="editMobile"
                          value={editData.mobile}
                          onChange={(e) =>
                            setEditData((prev) => ({
                              ...prev,
                              mobile: e.target.value,
                            }))
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label htmlFor="editPan" className="form-label mb-0">
                          PAN
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="editPan"
                          value={editData.pan_number}
                          disabled
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="editKycDetails"
                          className="form-label mb-0"
                        >
                          KYC Details
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="editKycDetails"
                          value={
                            editData.ckyc_status === 1 ? "Completed" : "Pending"
                          }
                          disabled
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="editEsign" className="form-label mb-0">
                          E-Sign
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="editEsign"
                          value={editData.esign === 1 ? "Completed" : "Pending"}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Save changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default ClientDetails;
