import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Footer from "../../layout/Footer";
import Navbar from "../../layout/Navbar";
import ProgressBar from "../../ui/progressBar/ProgressBar";
import "./riskProfile.css";
import RiskProfileHeroImg from "../../../assets/login-1.png";

const RiskProfile = () => {
  const riskProfileApi = `${process.env.REACT_APP_URL}risk-profile`;
  const userRiskAnswersApi = `${process.env.REACT_APP_URL}user-risk-answer`;
  const userQandAapi = `${process.env.REACT_APP_URL}user-question-answers`;
  const [score, setScore] = useState(0);
  const navigate = useNavigate();
  const run = useRef(true);
  const userData = useSelector((state) => {
    return state.users.data;
  });
  const filledValues = [];
  const answers = [];

  var checkboxvalue = () => {
    var sum = 0;
    for (var i = 1; i < 22; i++) {
      var check = document.querySelector(
        `input[type=radio][name=q${i}]:checked`
      );
      if (check) {
        sum += parseInt(check.value);
      }
    }
    setScore(sum);
  };

  const handleScore = () => {
    var checkedBoxes = 0;
    var resultscore = 0;
    for (var i = 1; i < 22; i++) {
      resultscore = 0;
      var check = document.querySelector(`input[name=q${i}]:checked`);
      if (check) {
        resultscore = parseInt(check.value);
        answers.push(resultscore);
        checkedBoxes++;
      }
    }
    if (checkedBoxes == 21) {
      document.getElementById("questionsError").classList.add("d-none");
      userRiskAnswers(score, answers);
    } else {
      document.getElementById("questionsError").classList.remove("d-none");
    }
  };

  const userRiskAnswers = async (score, answers) => {
    try {
      const res = await fetch(userRiskAnswersApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          token: userData.token,
          answers: answers,
        }),
      });
      var data = await res.json();
    } catch (error) {
      console.log(error.message);
    }
    if (data.status === 200) {
      if (userData.step > 2) {
        switch (userData.step) {
          case 3:
            navigate("/suitability-assessment");
            break;
          // case 4:
          //   navigate("/fee-payment");
          //   break;
          case 4:
            navigate("/e-sign");
            break;
          default:
            navigate("/user-dashboard");
            break;
        }
      } else {
        navigate("/risk-result");
      }
    }
  };

  const storeRiskProfile = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(riskProfileApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          token: userData.token,
          risk_score: score,
        }),
      });
      var data = await res.json();
    } catch (error) {
      console.log(error.message);
    }
    if (data.status === 200) {
      handleScore(data.risklevel);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      const res = await fetch(userQandAapi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          token: userData.token,
        }),
      });
      var data = await res.json();
    } catch (error) {
      console.log(error);
    }
    if (data.status === 200) {
      filledValues.push(...data.data);
      if (filledValues.length === 21) {
        for (let i = 0; i < filledValues.length; i++) {
          let questionData = document.getElementsByName(`q${i + 1}`);
          setScore((prev) => prev + parseInt(filledValues[i][2]));
          for (let j = 0; j < questionData.length; j++) {
            if (questionData[j].value === filledValues[i][2]) {
              questionData[j].setAttribute("checked", true);
            }
          }
        }
      }
    }
  }, [userData.token, userQandAapi]);

  useEffect(() => {
    if (run.current) {
      if (!userData.token) {
        navigate("/");
      }
      fetchData();
    }
    return () => {
      run.current = false;
    };
  }, [fetchData, navigate, userData]);

  return (
    <section className="risk-background">
      <div
        className="container risk-profile-container"
        id="risk-profile-container"
      >
        <Navbar />
        <ProgressBar activestep="1" />
        <div className="row">
          <div className="col-12 col-md-7 card shadow my-5 pb-5">
            <h4 className="mt-3 text-center text-primary">
              Answer these questions to get your Risk Score
            </h4>
            <form onChange={checkboxvalue}>
              <div
                className="all-questions-container "
                id="all-questions-container"
              >
                <div className="question-container">
                  <p className="mb-2 fs-5 question">What is your age?</p>
                  <div className="d-flex flex-column">
                    <div className="options">
                      <input type="radio" name="q1" id="q1-1" value="3" />
                      <label htmlFor="q1-1">Less than 40 ( Score 3 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q1" id="q1-2" value="2" />
                      <label htmlFor="q1-2">41-55 ( Score 2 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q1" id="q1-3" value="1" />
                      <label htmlFor="q1-3">More than 55 ( Score 1 )</label>
                    </div>
                  </div>
                </div>
                <div className="question-container">
                  <p className="mb-2 fs-5 question">
                    What is your income per year?
                  </p>
                  <div className="d-flex flex-column">
                    <div className="options">
                      <input type="radio" name="q2" id="q2-1" value="1" />
                      <label htmlFor="q2-1">
                        Less than 10 Lakh ( Score 1 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q2" id="q2-2" value="2" />
                      <label htmlFor="q2-2">10Lacs - 50Lacs ( Score 2 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q2" id="q2-3" value="3" />
                      <label htmlFor="q2-3">
                        Greater than 50Lacs ( Score 3 )
                      </label>
                    </div>
                  </div>
                </div>
                <div className="question-container">
                  <p className="mb-2 fs-5 question">
                    Pick one outcome from your investment after 1 year?
                  </p>
                  <div className="d-flex flex-column">
                    <div className="options">
                      <input type="radio" name="q3" id="q3-1" value="1" />
                      <label htmlFor="q3-1">
                        Average Returns: 7%, Best Returns: 12%, Worst Returns:
                        -5% ( Score 1 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q3" id="q3-2" value="2" />
                      <label htmlFor="q3-2">
                        Average Returns: 12%, Best Returns: 20%, Worst Returns:
                        -15% ( Score 2 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q3" id="q3-3" value="3" />
                      <label htmlFor="q3-3">
                        Average Returns: 15%, Best Returns: 30%, Worst Returns:
                        -20% ( Score 3 )
                      </label>
                    </div>
                  </div>
                </div>
                <div className="question-container ">
                  <p className="mb-2 fs-5 question">
                    What percent of your monthly income are you planning to
                    invest?
                  </p>
                  <div className="d-flex flex-column">
                    <div className="options">
                      <input type="radio" name="q4" id="q4-1" value="1" />
                      <label htmlFor="q4-1">Less than 20% ( Score 1 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q4" id="q4-2" value="2" />
                      <label htmlFor="q4-2">20% - 50% ( Score 2 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q4" id="q4-3" value="3" />
                      <label htmlFor="q4-3">More than 50% ( Score 3 )</label>
                    </div>
                  </div>
                </div>
                <div className="question-container ">
                  <p className="mb-2 fs-5 question">
                    What percent of your monthly income do you use to pay loans
                    and liabilities?
                  </p>
                  <div className="d-flex flex-column">
                    <div className="options">
                      <input type="radio" name="q5" id="q5-1" value="3" />
                      <label htmlFor="q5-1">Less than 20% ( Score 3 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q5" id="q5-2" value="2" />
                      <label htmlFor="q5-2">20% - 50% ( Score 2 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q5" id="q5-3" value="1" />
                      <label htmlFor="q5-3">More than 50% ( Score 1 )</label>
                    </div>
                  </div>
                </div>
                <div className="question-container ">
                  <p className="mb-2 fs-5 question">
                    Select all the investments you currently hold (Multiple
                    Choice)
                  </p>
                  <div className="d-flex flex-column">
                    <div className="options">
                      <input type="radio" name="q6" id="q6-1" value="1" />
                      <label htmlFor="q6-1">Bank FD ( Score 1 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q6" id="q6-2" value="2" />
                      <label htmlFor="q6-2">Mutual Funds ( Score 2 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q6" id="q6-3" value="3" />
                      <label htmlFor="q6-3">Stocks ( Score 3 )</label>
                    </div>
                  </div>
                </div>
                <div className="question-container ">
                  <p className="mb-2 fs-5 question">
                    What is your investment objective?
                  </p>
                  <div className="d-flex flex-column">
                    <div className="options">
                      <input type="radio" name="q7" id="q7-1" value="1" />
                      <label htmlFor="q7-1">
                        Protect invested capital with very low chance of a loss
                        (investment horizon - &lt; 2 years) ( Score 1 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q7" id="q7-2" value="2" />
                      <label htmlFor="q7-2">
                        Seek balance between invested capital growth and
                        protection (investment horizon - 2-5 years) ( Score 2 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q7" id="q7-3" value="3" />
                      <label htmlFor="q7-3">
                        Seek long term wealth creation with chances of higher
                        short term loss (investment horizon - &gt; 5 years) (
                        Score 3 )
                      </label>
                    </div>
                  </div>
                </div>
                <div className="question-container">
                  <p className="mb-2 fs-5 question">
                    What kind of risk taker would you describe yourself as?
                  </p>
                  <div className="d-flex flex-column">
                    <div className="options">
                      <input type="radio" name="q8" id="q8-1" value="2" />
                      <label htmlFor="q8-1">Low ( Score 2 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q8" id="q8-2" value="4" />
                      <label htmlFor="q8-2">Moderately Low ( Score 4 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q8" id="q8-3" value="6" />
                      <label htmlFor="q8-3">Average ( Score 6 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q8" id="q8-4" value="8" />
                      <label htmlFor="q8-4">Moderately High ( Score 8 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q8" id="q8-5" value="10" />
                      <label htmlFor="q8-5">High ( Score 10 )</label>
                    </div>
                  </div>
                </div>
                <div className="question-container">
                  <p className="mb-2 fs-5 question">
                    When you think of the word "risk", what word best describes
                    your thoughts?
                  </p>
                  <div className="d-flex flex-column">
                    <div className="options">
                      <input type="radio" name="q9" id="q9-1" value="2" />
                      <label htmlFor="q9-1">Danger ( Score 2 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q9" id="q9-2" value="4" />
                      <label htmlFor="q9-2">Uncertainity ( Score 4 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q9" id="q9-3" value="6" />
                      <label htmlFor="q9-3">Caution ( Score 6 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q9" id="q9-4" value="8" />
                      <label htmlFor="q9-4">Opportunity ( Score 8 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q9" id="q9-5" value="10" />
                      <label htmlFor="q9-5">Excitement ( Score 10 )</label>
                    </div>
                  </div>
                </div>
                <div className="question-container">
                  <p className="mb-2 fs-5 question">
                    How do you manage when you are faced with an important
                    decision?
                  </p>
                  <div className="d-flex flex-column">
                    <div className="options">
                      <input type="radio" name="q10" id="q10-1" value="2" />
                      <label htmlFor="q10-1">
                        I prefer not to be the decision maker ( Score 2 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q10" id="q10-2" value="4" />
                      <label htmlFor="q10-2">
                        I worry whether I will make the right decision ( Score 4
                        )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q10" id="q10-3" value="6" />
                      <label htmlFor="q10-3">
                        I wonder what the right decision is ( Score 6 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q10" id="q10-4" value="8" />
                      <label htmlFor="q10-4">
                        I am confident I will make the right decision ( Score 8
                        )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q10" id="q10-5" value="10" />
                      <label htmlFor="q10-5">
                        I enjoy being the decision maker ( Score 10 )
                      </label>
                    </div>
                  </div>
                </div>
                <div className="question-container">
                  <p className="mb-2 fs-5 question">
                    When faced with a major financial decision, what are you
                    most concerned about?
                  </p>
                  <div className="d-flex flex-column">
                    <div className="options">
                      <input type="radio" name="q11" id="q11-1" value="2" />
                      <label htmlFor="q11-1">
                        Always the possible losses ( Score 2 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q11" id="q11-2" value="4" />
                      <label htmlFor="q11-2">
                        Usually the possible losses ( Score 4 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q11" id="q11-3" value="6" />
                      <label htmlFor="q11-3">Neutral ( Score 6 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q11" id="q11-4" value="8" />
                      <label htmlFor="q11-4">
                        Usually the possible gains ( Score 8 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q11" id="q11-5" value="10" />
                      <label htmlFor="q11-5">
                        Always the possible gains ( Score 10 )
                      </label>
                    </div>
                  </div>
                </div>
                <div className="question-container">
                  <p className="mb-2 fs-5 question">
                    Imagine you were in a job where you could choose between
                    salary, bonus or a mix of both. Which would you choose?
                    Client Score
                  </p>
                  <div className="d-flex flex-column">
                    <div className="options">
                      <input type="radio" name="q12" id="q12-1" value="2" />
                      <label htmlFor="q12-1">All Salary ( Score 2 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q12" id="q12-2" value="4" />
                      <label htmlFor="q12-2">
                        Mainly salary with a small potential bonus ( Score 4 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q12" id="q12-3" value="6" />
                      <label htmlFor="q12-3">
                        Average salary with a modest potential bonus ( Score 6 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q12" id="q12-4" value="8" />
                      <label htmlFor="q12-4">
                        Below average salary with a reasonable potential bonus (
                        Score 8 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q12" id="q12-5" value="10" />
                      <label htmlFor="q12-5">
                        Low salary with a large potential bonus ( Score 10 )
                      </label>
                    </div>
                  </div>
                </div>
                <div className="question-container">
                  <p className="mb-2 fs-5 question">
                    What is your investment knowledge and experience?
                  </p>
                  <div className="d-flex flex-column">
                    <div className="options">
                      <input type="radio" name="q13" id="q13-1" value="2" />
                      <label htmlFor="q13-1">
                        No experience with investing and virtually no knowledge
                        ( Score 2 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q13" id="q13-2" value="4" />
                      <label htmlFor="q13-2">
                        Some experience with investing but have very little
                        knowledge( Score 4 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q13" id="q13-3" value="6" />
                      <label htmlFor="q13-3">
                        Some experience with investing and gained some knowledge
                        ( Score 6 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q13" id="q13-4" value="8" />
                      <label htmlFor="q13-4">
                        Adequate experience with investing and adequate
                        knowledge for my investment needs ( Score 8 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q13" id="q13-5" value="10" />
                      <label htmlFor="q13-5">
                        Extensive experience with investing based on my own
                        research and knowledge ( Score 10 )
                      </label>
                    </div>
                  </div>
                </div>
                <div className="question-container">
                  <p className="mb-2 fs-5 question">
                    How long do you intend to invest? (For superannuation
                    investors, unless you are within 7 years of gaining access
                    to your superannuation, please select 'long term')
                  </p>
                  <div className="d-flex flex-column">
                    <div className="options">
                      <input type="radio" name="q14" id="q14-1" value="2" />
                      <label htmlFor="q14-1">
                        Parking (less than 1 year) ( Score 2 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q14" id="q14-2" value="4" />
                      <label htmlFor="q14-2">
                        Short term (1-2 years) ( Score 4 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q14" id="q14-3" value="6" />
                      <label htmlFor="q14-3">
                        Medium term (2-5 years) ( Score 6 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q14" id="q14-4" value="8" />
                      <label htmlFor="q14-4">
                        Medium-long term (5-7 years) ( Score 8 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q14" id="q14-5" value="10" />
                      <label htmlFor="q14-5">
                        Long term (more than 7 years) ( Score 10 )
                      </label>
                    </div>
                  </div>
                </div>
                <div className="question-container">
                  <p className="mb-2 fs-5 question">
                    Will you need access to the funds invested during the term
                    of the investment? (For superannuation investors, unless you
                    are retired, please select 'no')
                  </p>
                  <div className="d-flex flex-column">
                    <div className="options">
                      <input type="radio" name="q15" id="q15-1" value="-10" />
                      <label htmlFor="q15-1">Yes ( Score -10 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q15" id="q15-2" value="0" />
                      <label htmlFor="q15-2">No ( Score 0 )</label>
                    </div>
                  </div>
                </div>
                <div className="question-container">
                  <p className="mb-2 fs-5 question">
                    What would you do if your investment dropped in value from
                    Rs 100,000 to Rs 85,000?
                  </p>
                  <div className="d-flex flex-column">
                    <div className="options">
                      <input type="radio" name="q16" id="q16-1" value="2" />
                      <label htmlFor="q16-1">
                        Move the entire investment to cash ( Score 2 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q16" id="q16-2" value="4" />
                      <label htmlFor="q16-2">
                        Move some of the investment to cash ( Score 4 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q16" id="q16-3" value="6" />
                      <label htmlFor="q16-3">Do Nothing ( Score 6 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q16" id="q16-4" value="8" />
                      <label htmlFor="q16-4">
                        By more of the investment ( Score 8 )
                      </label>
                    </div>
                  </div>
                </div>
                <div className="question-container">
                  <p className="mb-2 fs-5 question">
                    What is the most aggressive investment you've ever made?
                  </p>
                  <div className="d-flex flex-column">
                    <div className="options">
                      <input type="radio" name="q17" id="q17-1" value="2" />
                      <label htmlFor="q17-1">Bank Account ( Score 2 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q17" id="q17-2" value="4" />
                      <label htmlFor="q17-2">Own home ( Score 4 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q17" id="q17-3" value="6" />
                      <label htmlFor="q17-3">
                        Investment property ( Score 6 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q17" id="q17-4" value="8" />
                      <label htmlFor="q17-4">Managed funds ( Score 8 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q17" id="q17-5" value="10" />
                      <label htmlFor="q17-5">
                        Shares / Equity derivatives / Commodity derivatives (
                        Score 10 )
                      </label>
                    </div>
                  </div>
                </div>
                <div className="question-container">
                  <p className="mb-2 fs-5 question">
                    Have you ever borrowed money to make an investment (other
                    than for your home)? This is leverage
                  </p>
                  <div className="d-flex flex-column">
                    <div className="options">
                      <input type="radio" name="q18" id="q18-1" value="8" />
                      <label htmlFor="q18-1">Yes ( Score 8 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q18" id="q18-2" value="0" />
                      <label htmlFor="q18-2">No ( Score 0 )</label>
                    </div>
                  </div>
                </div>
                <div className="question-container">
                  <p className="mb-2 fs-5 question">
                    How do you feel about borrowing money to invest?
                  </p>
                  <div className="d-flex flex-column">
                    <div className="options">
                      <input type="radio" name="q19" id="q19-1" value="2" />
                      <label htmlFor="q19-1">I am concerned ( Score 2 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q19" id="q19-2" value="4" />
                      <label htmlFor="q19-2">
                        I am open to this strategy ( Score 4 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q19" id="q19-3" value="6" />
                      <label htmlFor="q19-3">
                        I am comfortable ( Score 6 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q19" id="q19-4" value="8" />
                      <label htmlFor="q19-4">
                        I am very comfortable ( Score 8 )
                      </label>
                    </div>
                  </div>
                </div>
                <div className="question-container">
                  <p className="mb-2 fs-5 question">
                    Imagine you have set a goal to retire at a particular age
                    and have also determined the lifestyle you would like in
                    retirement. Your financial adviser informs you that in order
                    to achieve the lifestyle you desire if you are to retire at
                    that specified age, you will need to make investments of a
                    higher risk that you would normally be comfortable with.
                  </p>
                  <div className="d-flex flex-column">
                    <div className="options">
                      <input type="radio" name="q20" id="q20-1" value="0" />
                      <label htmlFor="q20-1">
                        I would rather alter my retirement goals than take any
                        more risk ( Score 0 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q20" id="q20-2" value="4" />
                      <label htmlFor="q20-2">
                        I would take a combination of higher risk investments as
                        well as altering my retirement goals ( Score 4 )
                      </label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q20" id="q20-3" value="8" />
                      <label htmlFor="q20-3">
                        would rather take a higher risk investment than alter my
                        retirement goals ( Score 8 )
                      </label>
                    </div>
                  </div>
                </div>
                <div className="question-container">
                  <p className="mb-2 fs-5 question">
                    Are you comfortable with exposure to futures and options
                    strategy(derivatives)
                  </p>
                  <div className="d-flex flex-column">
                    <div className="options">
                      <input type="radio" name="q21" id="q21-1" value="0" />
                      <label htmlFor="q21-1">Not comfortable ( Score 0 )</label>
                    </div>
                    <div className="options">
                      <input type="radio" name="q21" id="q21-2" value="10" />
                      <label htmlFor="q21-2">
                        Yes i am okay with futures and options exposure in my
                        trading account ( Score 10 )
                      </label>
                    </div>
                  </div>
                </div>
                <p className="text-danger pt-4 d-none" id="questionsError">
                  Please answer all the questions to proceed further. Thank you.
                </p>
                <button
                  className="btn btn-lg text-white check-score-btn mt-2 text-decoration-none"
                  onClick={storeRiskProfile}
                >
                  Next
                </button>
              </div>
            </form>
          </div>
          <div className="col-lg-5 d-none d-lg-block position-relative mt-4">
            <div
              id="score-container"
              className="text-center shadow bg-white rounded-3 p-3 position-fixed"
            >
              <p id="risk-score" className="fs-2 m-0">
                {score || 0}
              </p>
              <h4 className="m-0 py-2">Risk Score</h4>
            </div>
            <img
              src={RiskProfileHeroImg}
              alt="risk-profile-hero-img"
              className="side-img"
            />
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default RiskProfile;
