import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { AdminNavbar } from "../../layout/adminNavbar";
import { AdminSidebar } from "../../layout/adminSidebar";
import { Loader } from "../../ui/loader/Loader";
import DeleteModal from "../../ui/deleteModal/DeleteModal";
import Footer from "../../layout/Footer";
import "./onBoarding.css";

const ClientOnBoarding = () => {
  const [allClients, setAllClients] = useState([]);
  const [openModal, setOpenModal] = useState({
    open: false,
    userId: null,
  });
  const onBoardingClientsApi = `${process.env.REACT_APP_URL}onboarding-clients`;
  const approvedClientsApi = `${process.env.REACT_APP_URL}approved-onboard-clients`;
  const deleteClientApi = `${process.env.REACT_APP_URL}delete-client-onboarding`;
  const [isLoading, setIsLoading] = useState(false);

  const OnBoardingClients = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await fetch(onBoardingClientsApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      var data = await res.json();
    } catch (error) {
      console.log(error.message);
    }
    setIsLoading(false);
    if (data.status === 200) {
      setAllClients(data.data);
    } else if (data.status === 201) {
      setAllClients([]);
    }
  }, [onBoardingClientsApi]);

  const ApprovedClients = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(approvedClientsApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      var data = await res.json();
      console.log(data);
    } catch (error) {
      console.log(error.message);
    }
    setIsLoading(false);
    if (data.status === 200) {
      setAllClients(data.data);
    } else if (data.status === 201) {
      setAllClients([]);
    }
  };

  const DeleteClient = async (userId) => {
    try {
      const res = await fetch(deleteClientApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
        }),
      });
      var data = await res.json();
    } catch (error) {
      console.log(error.message);
    }
    if (data.status === 200) {
      setOpenModal({ open: false, userId: null });
      window.location.reload();
    }
  };

  const getUserId = (id) => {
    setOpenModal({ open: true, userId: id });
  };

  useEffect(() => {
    OnBoardingClients();
  }, [OnBoardingClients]);

  return (
    <section className="client-onboarding-container">
      <AdminNavbar />
      {isLoading ? (
        <div className="d-flex justify-content-center mt-5">
          <Loader />
        </div>
      ) : (
        <>
          <div className="row m-0">
            <div className="col-12 col-lg-2 p-0 bg-primary">
              <AdminSidebar />
            </div>
            <div className="col-12 col-lg-10  pt-3">
              <div className="d-flex justify-content-between align-items-center px-3">
                <h4 className="text-primary mb-0">Client OnBoarding Details</h4>
                <div>
                  <button
                    onClick={() => OnBoardingClients()}
                    className="btn btn-primary me-2"
                  >
                    All Clients
                  </button>
                  <button
                    onClick={() => ApprovedClients()}
                    className="btn btn-primary me-2"
                  >
                    Approved Clients
                  </button>
                  <button className="btn btn-primary">Renewal Clients</button>
                </div>
              </div>
              <table className="table table-striped table-bordered table-hover mt-3 onboarding-table">
                <thead className="text-center">
                  <tr>
                    <th scope="col">Reg Date</th>
                    <th scope="col" className="cstm-big-width">
                      Name
                    </th>
                    <th scope="col" className="cstm-big-width">
                      Email
                    </th>
                    <th scope="col">Mobile</th>
                    <th scope="col" className="cstm-big-width">
                      Risk Category
                    </th>
                    <th scope="col" className="cstm-big-col">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {allClients.length < 1 ? (
                    <tr className="text-center">
                      <td colSpan="6">No Data Found</td>
                    </tr>
                  ) : (
                    allClients.map((d) => (
                      <tr key={d.id}>
                        <td>{d.created_at}</td>
                        <td>{d.name}</td>
                        <td>{d.email}</td>
                        <td>{d.mobile}</td>
                        <td
                          className={
                            d.risk_level === "High risk profile"
                              ? "text-danger"
                              : d.risk_level === "Medium risk profile"
                              ? "text-primary"
                              : "text-success"
                          }
                        >
                          {d.risk_level}
                        </td>
                        <td className="d-flex justify-content-around">
                          <Link
                            to={{
                              pathname: "/client-details",
                            }}
                            state={{ userId: d.id }}
                          >
                            <i className="bi bi-eye-fill text-success"></i>
                          </Link>
                          <p onClick={() => getUserId(d.id)}>
                            <i className="bi bi-trash-fill text-danger action-icon"></i>
                          </p>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <DeleteModal
              open={openModal}
              onClose={() => setOpenModal(false)}
              onDelete={(userId) => DeleteClient(userId)}
            />
          </div>
          <Footer />
        </>
      )}
    </section>
  );
};

export default ClientOnBoarding;
