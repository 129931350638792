import React from "react";
import { Link } from "react-router-dom";
import { AdminNavbar } from "../../layout/adminNavbar";
import { AdminSidebar } from "../../layout/adminSidebar";
import Footer from "../../layout/Footer";

const DocumentVault = () => {
  return (
    <section className="client-onboarding-container">
      <AdminNavbar />
      <div className="row m-0">
        <div className="col-12 col-lg-2 p-0 bg-primary">
          <AdminSidebar />
        </div>
        <div className="col-12 col-lg-10 my-5">
          <div className="p-3 pt-0">
            <h4 className="text-start text-primary mb-4">Document Vault</h4>
            <div className="w-75 justify-content-between d-flex align-items-center">
              <Link
                to="/view-risk-profiles"
                className="bg-warning shadow-lg p-5 rounded h3"
                style={{
                  cursor: "pointer",
                  backgroundColor: "#FFC600",
                  color: "#034EA2",
                }}
              >
                View Risk Profiles
              </Link>
              <Link
                to="/view-esign-docs"
                className="bg-warning shadow-lg p-5 rounded h3"
                style={{
                  cursor: "pointer",
                  backgroundColor: "#FFC600",
                  color: "#034EA2",
                }}
              >
                View Esign Documents
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default DocumentVault;
