import React, { useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom/dist";
import { updateStep } from "../../../store/slices/UserSlice";

const Success = () => {
  const getAadhaarDetailsApi = `${process.env.REACT_APP_URL}get-aadhaar-details`;
  const esignDetailsApi = `${process.env.REACT_APP_URL}esign-document-details`;
  const ref = useRef(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userData = useSelector((state) => {
    return state.users.data;
  });
  const dispatch = useDispatch();

  const getAadhaarDetails = useCallback(async () => {
    if (searchParams.get("status") !== "success") {
      return;
    }
    try {
      const res = await fetch(getAadhaarDetailsApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          digio_doc_id: searchParams.get("digio_doc_id"),
        }),
      });
      var response = await res.json();
    } catch (error) {
      console.log(error);
    }
    if (response.status === 200) {
      navigate("/e-kyc", { state: { response: response } });
    }
  }, [navigate, getAadhaarDetailsApi, searchParams]);

  const esignDetails = useCallback(async () => {
    if (searchParams.get("status") !== "success") {
      return;
    }
    try {
      const res = await fetch(esignDetailsApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          token: userData.token,
          digio_doc_id: searchParams.get("digio_doc_id"),
        }),
      });
      var response = await res.json();
    } catch (error) {
      console.log(error);
    }
    if (response.status === 200) {
      dispatch(updateStep({ ...userData, step: 5 }));
      window.location.replace("/#/user-dashboard");
    }
  }, [esignDetailsApi, searchParams, dispatch, userData]);

  useEffect(() => {
    if (ref.current) {
      const message = searchParams.get("message");
      if (message.includes("KYC")) {
        getAadhaarDetails();
      } else {
        setTimeout(() => {
          esignDetails();
        }, 10000);
      }
    }
    return () => {
      ref.current = false;
    };
  }, [getAadhaarDetails, esignDetails, searchParams]);

  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      {searchParams.get("status") !== "success" ? (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <i
            className="fa-solid fa-circle-xmark fa-2xl mb-5"
            style={{ color: "#d91c1c", fontSize: "4rem" }}
          ></i>
          <p className="fs-5">E-Sign Failed. Please try again.</p>
          <Link to="/" className="btn btn-primary">
            Go Back
          </Link>
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <i
            className="fa-solid fa-circle-check fa-2xl mb-5"
            style={{ color: "#18dc26", fontSize: "4rem" }}
          ></i>
          <p className="fs-5">
            Onboarding successful. You will be redirected to your dashboard in
            10 seconds.
          </p>
        </div>
      )}
    </div>
  );
};

export default Success;
