import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../layout/Navbar";
import ProgressBar from "../../ui/progressBar/ProgressBar";
import "./ekyc.css";
import digio from "../../../assets/digio-logo.png";
import Footer from "../../layout/Footer";
import PanResult from "../../utils/panResult";
import AadhaarForm from "../../utils/aadhaarForm";
import { updateStep } from "../../../store/slices/UserSlice";

const Ekyc = () => {
  const panApi = `${process.env.REACT_APP_URL}pan-verification`;
  const manualVerificationApi = `${process.env.REACT_APP_URL}ckyc`;
  const aadhaarVerificationApi = `${process.env.REACT_APP_URL}aadhaar-verification`;
  const userData = useSelector((state) => {
    return state.users.data;
  });

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const ref = useRef(true);
  const [showForm, setShowForm] = useState(false);
  const [panInfo, setPanInfo] = useState([]);
  const [formType, setFormType] = useState("");
  const [userDetails, setUserDetails] = useState({
    kyc_type: "pan",
    father_name: "",
    gender: "male",
    dob: "",
    aadhaar_number: "",
    reference_id: "",
    pan_number: "",
    ckyc_number: "",
    address: "",
    nominee_name: "",
    nominee_pan: "",
    nominee_mobile: "",
    nominee_email: "",
    nominee_address: "",
  });

  const checkFormType = () => {
    document.getElementById("pan-form").classList.add("d-none");
    document.getElementById("aadhaar-form").classList.add("d-none");
    document.getElementById("manual-form").classList.add("d-none");
    var checked_form = document.querySelector('input[name = "kyc"]:checked');
    if (checked_form) {
      document
        .getElementById(checked_form.id + "-form")
        .classList.remove("d-none");
      setFormType(checked_form.id);
    }
  };

  useEffect(() => {
    if (ref.current === true) {
      if (!userData.token) {
        navigate("/");
      }
      if (location.state === null) return;
      else {
        document.getElementById("pan-form").classList.add("d-none");
        document.getElementById("aadhaar-form").classList.remove("d-none");
        setFormType("aadhaar");
      }
    }
    return () => {
      ref.current = false;
    };
  }, [userData, navigate, location.state]);

  const verifyPan = async (e) => {
    e.preventDefault();
    document.getElementById("pan-waiting-text").classList.remove("d-none");
    document.getElementById("pan-error-text").classList.add("d-none");

    var panNumber = document.getElementById("panNum").value;
    var dob = document.getElementById("dob").value;
    try {
      const res = await fetch(panApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          pan: panNumber,
          dob: dob,
        }),
      });
      var response = await res.json();
    } catch (error) {
      console.log(error);
    }
    if (response.status === 200) {
      document.getElementById("pan-waiting-text").classList.add("d-none");
      setPanInfo(response);
      setShowForm(true);
    } else {
      document.getElementById("pan-error-text").classList.remove("d-none");
      setShowForm(false);
    }
  };

  const manualVerification = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(manualVerificationApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          user_token: userData.token,
          kyc_type: formType,
          name: userDetails.name,
          father_name: userDetails.father_name,
          gender: userDetails.gender,
          dob: userDetails.dob,
          aadhaar_number: userDetails.aadhaar_number,
          reference_id: userDetails.reference_id,
          pan_number: userDetails.pan_number,
          ckyc_number: userDetails.ckyc_number,
          address: userDetails.address,
          nominee_name: userDetails.nominee_name,
          nominee_pan: userDetails.nominee_pan,
          nominee_mobile: userDetails.nominee_mobile,
          nominee_email: userDetails.nominee_email,
          nominee_address: userDetails.nominee_address,
        }),
      });
      var data = await res.json();
    } catch (error) {
      console.log(error);
    }
    if (data.status === 200) {
      dispatch(updateStep({ ...userData, step: 4 }));
      navigate("/e-sign");
    }
  };

  const aadhaarVerification = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(aadhaarVerificationApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          user_id: userData.token,
        }),
      });
      var data = await res.json();
      window.location.replace(`${data}`);
    } catch (error) {
      console.log(error);
    }
  };

  const updateManualFormDetails = (e) => {
    setUserDetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <section>
      <Navbar />
      <ProgressBar activestep="3" />
      <div className="container py-5">
        <p>Choose a KYC option</p>
        <div className="border-bottom pb-3 d-flex justify-content-between">
          <form className="d-flex w-50" onChange={checkFormType}>
            <div className="input-group align-items-center">
              <input type="radio" name="kyc" id="pan" />
              <label htmlFor="pan" className="mb-0 ms-1">
                Fetch from cKYC
              </label>
            </div>
            <div className="input-group align-items-center">
              <input
                type="radio"
                name="kyc"
                id="aadhaar"
                defaultChecked={
                  location.state !== null &&
                  location.state.response.showForm === true
                    ? true
                    : false
                }
              />
              <label htmlFor="aadhaar" className="mb-0 ms-1">
                Fetch from Aadhaar
              </label>
            </div>
            <div className="input-group align-items-center">
              <input type="radio" name="kyc" id="manual" />
              <label htmlFor="manual" className="mb-0 ms-1">
                Fillup details manually
              </label>
            </div>
          </form>
          <Link to="/risk-profile" className="btn btn-primary">
            Edit Risk Score
          </Link>
        </div>
        <div id="pan-form">
          <p className="text-danger small mt-2 d-none" id="pan-waiting-text">
            Please wait, the process may take couple of minutes.
          </p>
          <p className="text-danger small mt-2 d-none" id="pan-error-text">
            Failed to verify pan details, please try again.
          </p>
          <form className="mt-3" onSubmit={verifyPan}>
            <input type="text" value="cKYC-form" hidden readOnly />
            <div className="row">
              <div className="mb-3 col-12 col-md-6 col-lg-4">
                <label htmlFor="panNum" className="form-label">
                  PAN Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="panNum"
                  placeholder="Pan Number"
                  style={{ textTransform: "uppercase" }}
                  required
                />
              </div>
              <div className="mb-3 col-12 col-md-6 col-lg-4">
                <label htmlFor="dob" className="form-label">
                  Date of Birth
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="dob"
                  placeholder="dd-mm-yyyy"
                  required
                />
              </div>
            </div>
            <div className="mb-4 form-check align-items-center">
              <input
                type="checkbox"
                className="form-check-input"
                id="auth-checkbox"
                required
              />
              <label
                className="form-check-label small mb-0 lh-sm"
                htmlFor="auth-checkbox"
              >
                I hereby authorize Moneydhan Investment Advisory LLP to pull my
                CKYC identifier on my behalf from the CKYC registry and
                therefore use the CKYC identifier to pull my KYC documents as
                maxhe available with CKYC registry.
              </label>
            </div>
            <button
              type="submit"
              className="ms-3 btn btn-lg btn-primary"
              id="pan-btn"
            >
              Verify
            </button>
          </form>
          {showForm && <PanResult formData={panInfo} formType={formType} />}
        </div>
        <div id="aadhaar-form" className="d-none">
          <form className="mt-3">
            <input type="text" value="aadhaar-form" hidden readOnly />
            <div className="d-flex align-items-center mb-3">
              <p className="mb-0">
                Fetch your KYC Details using Aadhaar Number with
              </p>
              <img src={digio} alt="digio-logo" width={"60px"} />
            </div>
            <button
              onClick={aadhaarVerification}
              className="btn btn-lg btn-primary"
            >
              Fetch Details
            </button>
          </form>
          {location.state && (
            <AadhaarForm
              formData={location.state.response}
              formType={formType}
            />
          )}
        </div>
        <div id="manual-form" className="d-none">
          <form className="mt-4" onSubmit={manualVerification}>
            <input type="text" value="manual-form" hidden readOnly />
            <h3 className="text-center mb-3 form-heading">Fill KYC Details</h3>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="mb-2">
                  <label htmlFor="name" className="mb-0 form-label">
                    Name*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={userDetails.name || ""}
                    onChange={updateManualFormDetails}
                    required
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="father-name" className="mb-0 form-label">
                    Father Name*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="father_name"
                    name="father_name"
                    value={userDetails.father_name || ""}
                    onChange={updateManualFormDetails}
                    aria-describedby="emailHelp"
                    required
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="gender" className="mb-0 form-label">
                    Gender*
                  </label>
                  <select
                    id="gender"
                    name="gender"
                    value={userDetails.gender}
                    onChange={updateManualFormDetails}
                    className="form-control form-select"
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div className="mb-2">
                  <label htmlFor="dateofbirth" className="mb-0 form-label">
                    Date of Birth*
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="dob"
                    name="dob"
                    onChange={updateManualFormDetails}
                    aria-describedby="emailHelp"
                    required
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="mb-2">
                  <label htmlFor="aadhaar" className="mb-0 form-label">
                    Aadhaar Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="aadhaar_number"
                    name="aadhaar_number"
                    onChange={updateManualFormDetails}
                    aria-describedby="emailHelp"
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="pan-number" className="mb-0 form-label">
                    PAN Number*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="pan_number"
                    name="pan_number"
                    onChange={updateManualFormDetails}
                    aria-describedby="emailHelp"
                    required
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="cKYC-number" className="mb-0 form-label">
                    cKYC Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="ckyc_number"
                    name="ckyc_number"
                    onChange={updateManualFormDetails}
                    aria-describedby="emailHelp"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="mb-2">
                  <label htmlFor="reference-id" className="mb-0 form-label">
                    Reference ID
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="reference_id"
                    name="reference_id"
                    onChange={updateManualFormDetails}
                    aria-describedby="emailHelp"
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="address" className="mb-0 form-label">
                    Address*
                  </label>
                  <textarea
                    name="address"
                    id="address"
                    onChange={updateManualFormDetails}
                    cols="30"
                    rows="4"
                    className="form-control"
                    required
                  ></textarea>
                </div>
              </div>
            </div>
            <h3 className="my-4 text-center form-heading">nominee Details</h3>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="mb-2">
                  <label htmlFor="nominee-name" className="form-label mb-0">
                    Name
                  </label>
                  <input
                    type="text"
                    id="nominee_name"
                    name="nominee_name"
                    onChange={updateManualFormDetails}
                    className="form-control"
                    placeholder="John Doe"
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="nominee_pan" className="form-label mb-0">
                    PAN Number
                  </label>
                  <input
                    type="text"
                    id="nominee_pan"
                    name="nominee_pan"
                    onChange={updateManualFormDetails}
                    className="form-control"
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="nominee-address" className="form-label mb-0">
                    Address
                  </label>
                  <textarea
                    name="nominee_address"
                    id="nominee_address"
                    onChange={updateManualFormDetails}
                    cols="30"
                    rows="3"
                    className="form-control"
                    placeholder="Old Mig, BHEL, Hyderabad, Telangana"
                  ></textarea>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-2">
                  <label htmlFor="nominee_mobile" className="form-label mb-0">
                    Mobile
                  </label>
                  <input
                    type="text"
                    id="nominee_mobile"
                    name="nominee_mobile"
                    onChange={updateManualFormDetails}
                    className="form-control"
                    placeholder="ex: 9082510209"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="nominee_email" className="form-label mb-0">
                    Email
                  </label>
                  <input
                    type="email"
                    id="nominee_email"
                    name="nominee_email"
                    onChange={updateManualFormDetails}
                    className="form-control"
                    placeholder="ex: john@gmail.com"
                  />
                </div>
                <div className="mb-2 form-check align-items-center d-flex p-0">
                  <input
                    type="checkbox"
                    id="joint-account"
                    className="checkbox"
                  />
                  <label htmlFor="joint-account" className="mb-0 ms-1">
                    Is this a Joint account ?
                  </label>
                </div>
              </div>
            </div>
            <button type="submit" className="btn-primary btn btn-lg my-4">
              Next
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Ekyc;
