import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateStep } from "../../../store/slices/UserSlice";
import Navbar from "../../layout/Navbar";
import ProgressBar from "../../ui/progressBar/ProgressBar";
import { Loader } from "../../ui/loader/Loader";
import "./suitability.css";

const SuitabilityPage = () => {
  const suitabilityDetailApi = `${process.env.REACT_APP_URL}suitability-detail`;
  const [isLoading, setIsLoading] = useState(true);
  const [suitabilityData, setSuitabilityData] = useState("");
  const userData = useSelector((state) => {
    return state.users.data;
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef(true);

  const suitabilityDetail = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await fetch(suitabilityDetailApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          token: userData.token,
        }),
      });
      var data = await res.json();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
    if (data.status === 200) {
      dispatch(updateStep({ ...userData, step: 3 }));
      setSuitabilityData(data.data);
      console.log(data);
    }
  }, [userData, suitabilityDetailApi, dispatch]);

  useEffect(() => {
    if (ref.current) {
      if (!userData.token) {
        navigate("/");
      }
      suitabilityDetail();
    }
    return () => {
      ref.current = false;
    };
  }, [suitabilityDetail, navigate, userData]);

  return (
    <section className="suitability-bg">
      <Navbar />
      <ProgressBar activestep="2" />
      <div className="container p-5">
        {isLoading ? (
          <div className="d-flex justify-content-center mb-5">
            <Loader />
          </div>
        ) : (
          <div className="d-flex justify-content-between mt-5">
            <div className="text-spacing">
              <p className="text mb-0">{suitabilityData.name || ""}</p>
              <p className="mb-0">{suitabilityData.email || ""}</p>
              <p className="mb-0">{suitabilityData.mobile || ""}</p>
              <div className="d-flex align-items-center">
                <p
                  className={
                    suitabilityData.risk_score > 100
                      ? "text-danger mb-0"
                      : suitabilityData.risk_score < 100 &&
                        suitabilityData.risk_score > 54
                      ? "text-success mb-0"
                      : "text-primary mb-0"
                  }
                >
                  <span className="fw-bold text-dark">Risk Score:</span>{" "}
                  {suitabilityData.risk_score} ({suitabilityData.risk_level})
                </p>
                <Link
                  to="/risk-profile"
                  className="btn btn-sm btn-primary ms-2"
                >
                  Edit Risk Score
                </Link>
              </div>
            </div>
            <p className="text text-spacing">
              {suitabilityData.created_at.split(" ")[0]}
            </p>
          </div>
        )}
        <table className="my-3 table table-bordered table-striped table-hover w-100 text-center">
          <thead>
            <tr>
              <th scope="col" className="py-2">
                Risk Category Type
              </th>
              <th scope="col">Risk Score</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2">Low Risk Category Client</td>
              <td>Risk Profile Score Below 54</td>
            </tr>
            <tr>
              <td className="py-2">Medium Risk Category Client</td>
              <td>Risk Profile Score Between 55-99</td>
            </tr>
            <tr>
              <td className="py-2">High Risk Category Client</td>
              <td>Risk Profile Score Above 100-143</td>
            </tr>
          </tbody>
        </table>
        <div className="mt-5">
          <h4>Process for Selecting Investments :</h4>
          <p className="text text-size text-spacing pt-3">
            As per SECURITIES AND EXCHANGE BOARD OF INDIA (INVESTMENT ADVISERS)
            REGULATIONS, 2013 Investment adviser shall ensure that <br />
            ● All investments on which investment advice is provided is
            appropriate to the risk profile of the client; <br />
            ● It has a documented process for selecting investments based on
            client’s investment objectives and financial situation; <br /> ● It
            understands the nature and risks of products or assets selected for
            clients; <br />
            ● It has a reasonable basis for believing that a recommendation or
            transaction entered into: <br />
            ● meets the client’s investment objectives; <br />
            ● is such that the client is able to bear any related investment
            risks consistent with its investment objectives and risk tolerance;
            <br />● is such that the client has the necessary experience and
            knowledge to understand the risks involved in the transaction.
          </p>
        </div>
        <div className="mt-5">
          <h4>Process for Suitability Assessment for client :</h4>
          <p className="text text-size text-spacing pt-3">
            Pursuant to the SEBI (INVESTMENT ADVISERS) REGULATIONS, 2013
            guidelines with respect to Suitability Assessment, we do proper risk
            profiling and risk assessment for each of the clients. As per risk
            analysis & client requirement we ensure that correct product/service
            as per client risk tolerance capacity is being offered which is
            suitable for client. Also, client subscribes for any of our services
            after evaluating our service features, pricing, risk involved, scope
            of service, mode of service delivery, trading strategy, your risk
            appetite, disclaimer, disclosure, terms of usage, T&C & NO REFUND
            POLICY explicitly communicated to client in personal meeting,
            understanding & being aware of the risk involved in Stock /
            Commodity / Currency market trading. After assessing, being
            affirmative with the service suitability & being satisfied with the
            services offered, client willingly subscribe for the
            service/services keeping in check their financial standing and
            assessing the capacity to absorb losses, whenever incurred.
          </p>
          <p className="text text-size text-spacing pt-3 text-secondary">
            To ensure that the advice offered to the client is suitable for the
            client we shall ensure that all investments on which investment
            advice is provided is appropriate to the risk profile of the client
            by categorizing client as low risk appetite, medium risk appetite
            and high risk appetite. A similar classification will be done for
            products/services after considering the risk and reward of the
            services offered and various SEBI guidelines in this regards. Client
            with Medium risk appetite is recommended to subscribe medium risk
            services only and client with high risk appetite is recommended to
            subscribe either medium or high risk services. We do not offer low
            risk services. Thus, it is ensured that advices which are being
            offered to clients are fully suitable.
          </p>
        </div>
        <p className="mb-1">{suitabilityData.name}</p>

        <Link to="/e-kyc" className="btn-primary btn fs-5 px-5 py-2 my-3">
          Next
        </Link>
      </div>
    </section>
  );
};

export default SuitabilityPage;
