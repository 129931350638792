import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteUser } from "../../store/slices/UserSlice";
import "../pages/suitability-assessment-page/suitability.css";
import moneydhan from "../../assets/moneydhan.png";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => {
    return state.users.data.token;
  });

  const Logout = () => {
    dispatch(deleteUser());
    navigate("/");
  };

  return (
    <nav className="container navbar-container rounded d-flex justify-content-between align-items-center mb-4 px-0">
      <div>
        <Link to="/" className="border-0">
          <img
            src={moneydhan}
            alt="moneydhan-logo"
            style={{ width: "10rem" }}
          />
        </Link>
      </div>
      {token.length < 1 ? (
        <Link to="/" className="h-100 btn btn-primary px-4 py-2">
          Login
        </Link>
      ) : (
        <button onClick={Logout} className="h-100 btn btn-primary px-4 py-2">
          Logout
        </button>
      )}
    </nav>
  );
};

export default Navbar;
