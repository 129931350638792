import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateStep } from "../../store/slices/UserSlice";

const PanResult = ({ formData }) => {
  const userData = useSelector((state) => {
    return state.users.data;
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formDetails, setFormDetails] = useState({
    aadhaar_number: "",
    reference_id: "",
    pan_number: "",
    ckyc_number: "",
    nominee_name: "",
    nominee_pan: "",
    nominee_mobile: "",
    nominee_email: "",
    nominee_address: "",
  });

  const verificationApi = `${process.env.REACT_APP_URL}ckyc`;

  const Verification = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(verificationApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          user_token: userData.token,
          kyc_type: "pan",
          name: formData.name,
          father_name: formData.fatherName,
          gender:
            formData.gender === "M"
              ? "Male"
              : formData.gender === "F"
              ? "Female"
              : "Other",
          dob: formData.dob,
          aadhaar_number: formDetails.aadhaar_number,
          reference_id: formDetails.reference_number,
          pan_number: formData.pan,
          ckyc_number: formDetails.ckyc_number,
          address: formData.address,
          nominee_name: formDetails.nominee_name,
          nominee_pan: formDetails.nominee_pan,
          nominee_mobile: formDetails.nominee_mobile,
          nominee_email: formDetails.nominee_email,
          nominee_address: formDetails.nominee_address,
        }),
      });
      var data = await res.json();
    } catch (error) {
      document.getElementById("errorMsg").classList.remove("d-none");
    }
    if (data.status === 200) {
      document.getElementById("errorMsg").classList.add("d-none");
      dispatch(updateStep({ ...userData, step: 4 }));
      navigate("/e-sign");
    }
  };

  const updateFormDetails = (e) => {
    setFormDetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <>
      {formData && (
        <form className="mt-3" onSubmit={Verification}>
          <input type="text" value="manual-form" hidden />
          <p className="text-danger mb-2 d-none" id="errorMsg">
            Sorry, something went wrong. Please try again.
          </p>
          <h3 className="text-center mb-3 form-heading">Fill KYC Details</h3>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="mb-2">
                <label htmlFor="user_name" className="mb-0 form-label">
                  Name*
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="user_name"
                  name="user_name"
                  value={formData.name}
                  required
                  readOnly
                  disabled
                />
              </div>
              <div className="mb-2">
                <label htmlFor="father-name" className="mb-0 form-label">
                  Father Name*
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="father_name"
                  name="father_name"
                  value={formData.fatherName}
                  disabled
                  readOnly
                />
              </div>
              <div className="mb-2">
                <label htmlFor="gender" className="mb-0 form-label">
                  Gender*
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="gender"
                  name="gender"
                  value={
                    formData.gender === "M"
                      ? "Male"
                      : formData.gender === "F"
                      ? "Female"
                      : "Other"
                  }
                  disabled
                  readOnly
                />
              </div>
              <div className="mb-2">
                <label htmlFor="dateofbirth" className="mb-0 form-label">
                  Date of Birth*
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="dob"
                  name="dob"
                  value={formData.dob}
                  required
                  disabled
                  readOnly
                />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="mb-2">
                <label htmlFor="aadhaar" className="mb-0 form-label">
                  Aadhaar Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="aadhaar_number"
                  name="aadhaar_number"
                  value={formDetails.aadhaar_number}
                  onChange={updateFormDetails}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="pan-number" className="mb-0 form-label">
                  PAN Number*
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="pan_number"
                  name="pan_number"
                  value={formData.pan}
                  readOnly
                  disabled
                />
              </div>
              <div className="mb-2">
                <label htmlFor="cKYC-number" className="mb-0 form-label">
                  cKYC Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="ckyc_number"
                  onChange={updateFormDetails}
                  name="ckyc_number"
                />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="mb-2">
                <label htmlFor="reference-id" className="mb-0 form-label">
                  Reference ID
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="reference_id"
                  name="reference_id"
                  value={formDetails.reference_id}
                  onChange={updateFormDetails}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="address" className="mb-0 form-label">
                  Address*
                </label>
                <textarea
                  name="address"
                  id="address"
                  cols="30"
                  rows="4"
                  className="form-control"
                  value={formData.address}
                  disabled
                  readOnly
                ></textarea>
              </div>
            </div>
          </div>
          <h3 className="my-4 text-center form-heading">nominee Details</h3>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="mb-2">
                <label htmlFor="nominee-name" className="form-label mb-0">
                  Name
                </label>
                <input
                  type="text"
                  id="nominee_name"
                  name="nominee_name"
                  className="form-control"
                  placeholder="Nominee's name"
                  value={formDetails.nominee_name}
                  onChange={updateFormDetails}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="nominee_pan" className="form-label mb-0">
                  PAN Number
                </label>
                <input
                  type="text"
                  id="nominee_pan"
                  name="nominee_pan"
                  className="form-control"
                  placeholder="nominee's pan number"
                  value={formDetails.nominee_pan}
                  onChange={updateFormDetails}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="nominee-address" className="form-label mb-0">
                  Address
                </label>
                <textarea
                  name="nominee_address"
                  id="nominee_address"
                  cols="30"
                  rows="3"
                  className="form-control"
                  placeholder="ex: Nominee address"
                  value={formDetails.nominee_address}
                  onChange={updateFormDetails}
                ></textarea>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="mb-2">
                <label htmlFor="nominee_mobile" className="form-label mb-0">
                  Mobile
                </label>
                <input
                  type="text"
                  id="nominee_mobile"
                  name="nominee_mobile"
                  className="form-control"
                  value={formDetails.nominee_mobile}
                  onChange={updateFormDetails}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="nominee_email" className="form-label mb-0">
                  Email
                </label>
                <input
                  type="email"
                  id="nominee_email"
                  name="nominee_email"
                  className="form-control"
                  placeholder="ex: nominee@gmail.com"
                  value={formDetails.nominee_email}
                  onChange={updateFormDetails}
                />
              </div>
              <div className="mb-2 form-check align-items-center d-flex p-0">
                <input
                  type="checkbox"
                  id="joint-account"
                  className="checkbox"
                />
                <label htmlFor="joint-account" className="mb-0 ms-1">
                  Is this a Joint account ?
                </label>
              </div>
            </div>
          </div>
          <button type="submit" className="btn-primary btn btn-lg my-4">
            Next
          </button>
        </form>
      )}
    </>
  );
};

export default PanResult;
