import React, { useCallback, useEffect, useState, useRef } from "react";
import { Loader } from "../../ui/loader/Loader";
import { AdminNavbar } from "../../layout/adminNavbar";
import { AdminSidebar } from "../../layout/adminSidebar";
import Footer from "../../layout/Footer";

const ViewEsignDocs = () => {
  const esignUsersapi = `${process.env.REACT_APP_URL}user-esign-details`;

  const [isLoading, setIsLoading] = useState(false);
  const [esignData, setEsignData] = useState([]);
  const ref = useRef(true);

  const esignUsers = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await fetch(esignUsersapi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      var data = await res.json();
      console.log(data);
    } catch (error) {
      console.log(error);
    }
    if (data.status === 200) {
      setEsignData(data.data);
    }
    setIsLoading(false);
  }, [esignUsersapi]);

  useEffect(() => {
    if (ref.current) {
      esignUsers();
    }
    return () => {
      ref.current = false;
    };
  }, [esignUsers]);

  return (
    <section>
      <AdminNavbar />
      <div className="row m-0">
        <div className="col-12 col-lg-2 p-0 bg-primary">
          <AdminSidebar />
        </div>
        <div className="col-12 col-lg-10 my-5">
          <h5 className="text-primary">e-Signed documents</h5>
          {isLoading ? (
            <div className="d-flex justify-content-center mt-5">
              <Loader />
            </div>
          ) : (
            <table className="table mt-2 table-bordered table-striped text-center">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Client</th>
                  <th scope="col">Email</th>
                  <th scope="col">Mobile</th>
                  <th scope="col">PAN</th>
                  <th scope="col">D.O.B</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {esignData.length > 0 ? (
                  esignData.map((data) => (
                    <tr key={data.id}>
                      <td>{data.created_at.slice(0, 10)}</td>
                      <td>{data.user_name}</td>
                      <td>{data.user_email}</td>
                      <td>{data.user_number}</td>
                      <td>{data.pan_number}</td>
                      <td>{data.dob}</td>
                      <td>
                        <a
                          href={
                            `https://onboarding.moneydhan.com/` +
                            data.esign_file
                          }
                          target="_blank"
                          rel="noreferrer"
                          className="text-primary"
                        >
                          Download
                        </a>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-center p-1">
                    <td colSpan={5}>No data found</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default ViewEsignDocs;
