import { useCallback, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Navbar from "../../layout/Navbar";
import Footer from "../../layout/Footer";
import ProgressBar from "../../ui/progressBar/ProgressBar";

const Esign = () => {
  const eSignApi = `${process.env.REACT_APP_URL}esign2`;
  const userDetialsApi = `${process.env.REACT_APP_URL}user-complete-details`;
  const userQandAapi = `${process.env.REACT_APP_URL}user-question-answers`;

  const [isChecked, setIsChecked] = useState(false);
  const [data, setData] = useState([]);
  const [riskData, setRiskData] = useState({});

  const ref = useRef(true);
  const navigate = useNavigate();
  const userData = useSelector((state) => {
    return state.users.data;
  });
  const today = Date.now();

  let futureDate = new Date(
    Date.now() + 1000 /*sec*/ * 60 /*min*/ * 60 /*hour*/ * 24 /*day*/ * 364
  );
  const currDate = new Intl.DateTimeFormat("en-UK", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }).format(today);

  const endDate = new Intl.DateTimeFormat("en-UK", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }).format(futureDate);

  const eSign = async () => {
    try {
      const res = await fetch(eSignApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          token: userData.token,
        }),
      });
      var data = await res.json();
      console.log(data);
      window.location.replace(`${data}`);
    } catch (error) {
      console.log(error);
    }
  };

  const userDetails = useCallback(async () => {
    try {
      const res = await fetch(userDetialsApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          token: userData.token,
        }),
      });
      var data = await res.json();
      console.log(data);
    } catch (error) {
      console.log(error);
    }
    if (data.status === 200) {
      setData(data.data);
      // setAmount(data.amount);
    }
  }, [userData, userDetialsApi]);

  const riskDetailsApi = useCallback(async () => {
    try {
      const res = await fetch(userQandAapi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          token: userData.token,
        }),
      });
      var data = await res.json();
    } catch (error) {
      console.log(error.message);
    }
    if (data.status === 200) {
      setRiskData(data.data);
    }
  }, [userData, userQandAapi]);

  useEffect(() => {
    if (userData.step !== 4) {
      navigate("/");
    }
    if (!userData.token) {
      navigate("/");
    }
    if (ref.current) {
      userDetails();
      riskDetailsApi();
    }
    return () => {
      ref.current = false;
    };
  }, [userDetails, riskDetailsApi, navigate, userData]);

  return (
    <section>
      <Navbar />
      <ProgressBar activestep="4" />
      <div className="container pt-5">
        <div className="container-headings d-flex justify-content-around border-bottom align-items-center pb-2">
          <p className="mb-0">Advisor</p>
          <p className="mb-0">Client</p>
          <p className="mb-0">Joint Holder</p>
          <Link to="/risk-profile" className="btn btn-primary">
            Edit Risk Score
          </Link>
        </div>
        <div className="analysis-process">
          <h3 className="text-center fw-bold mt-5 mb-3">
            RISK PROFILING QUESTIONNAIRE ANALYSIS PROCESS
          </h3>
          <p>
            For executing Risk profiling of clients we are managing a
            comprehensive risk profiling form. We obtain from the client, such
            information as is necessary for the purpose of giving investment
            advice, including the following:
          </p>
          <div className="pb-3">
            <p className="fw-bold mb-0">Age of the client:</p>
            <p className="mb-0">
              ● In our risk profiling we gather the information about the age
              group of the client.
            </p>
          </div>
          <div className="pb-3">
            <p className="fw-bold mb-0">
              Investment objectives including time for which they wish to stay
              invested:
            </p>
            <p className="mb-0">
              ● Whether they want to invest in short time horizon as it involves
              risk, or wants to go for long term investment.
            </p>
          </div>
          <div className="pb-3">
            <p className="fw-bold mb-0">The purposes of the investment:</p>
            <p className="mb-0">
              ● Whether they want to invest for their capital appreciation or
              for saving for a long term goal or for short term speculation and
              what are his or her priorities.
            </p>
          </div>
          <div className="pb-3">
            <p className="fw-bold mb-0">Income details of client:</p>
            <p className="mb-0">
              ● We obtain information about what is the source of Income of the
              client and what is his/her annual income, Expenses of the client
              and Fund that can be saved for future investment.
            </p>
            <p className="mb-0">
              ● We obtain information about their existing investments/ assets,
              whether it requires diversifying their investments.
            </p>
          </div>
          <div className="pb-3">
            <p className="fw-bold mb-0">Risk appetite/ tolerance:</p>
            <p className="mb-0">
              ● On the basis of discussion with client & analysis of their
              current assets and their current liabilities we decided the Risk
              appetite of the client and tolerance level of the client.
            </p>
          </div>
          <div className="pb-3">
            <p className="fw-bold mb-0">Liability/borrowing details:</p>
            <p className="mb-0">
              ● We obtain the necessary information about their current
              liabilities and borrowing from Banks or from financial
              institutions or from any other means of finance to access their
              tolerance level.
            </p>
          </div>
          <div className="pb-3">
            <p className="fw-bold mb-0">
              Process for assessing the risk a client is willing and able to
              take:
            </p>
            <p className="mb-0">
              ● We have a process for assessing the risk a client is willing and
              able to take. Our team work on data provided by clients and
              assesses whether the client is able to take high risk, moderate
              risk or low risk. As per the risk bearing capacity of client
              services which suits to their investment/trading needs are
              offered.
            </p>
            <p className="mb-0">
              ● Risk profile of the client is communicated to the client after
              risk assessment is done.
            </p>
            <p className="mb-0">
              ● Information provided by clients and their risk assessment is
              updated periodically.
            </p>
            <p className="mb-0">
              ● We have a comprehensive Risk Tolerance Questionnaire form which
              has to be filled before doing any investment so that we can access
              the client risk tolerance category. For risk profiling we have
              made a separate team where they ask the questions from the clients
              and note down the same for risk analyze & suitability assessment
              to identify the suitable services for the clients.
            </p>
            <p className="mb-0">
              ● Risk profiling of the client is done twice, once before offering
              a free trial and then again at the time of service subscription.
              Risk profile form used by us contains questions which are helpful
              to know the client’s capacity for absorbing loss & identifying
              whether clients are unwilling or unable to accept the risk of loss
              of capital and attribution of appropriate weight to the questions.
            </p>
            <p className="mb-0">
              ● Risk Profile of the client is communicated to all clients along
              with the questions and answers selected by the client, at the end
              of report a statement about the risk appetite of the client is
              always mentioned. Also, in the Process flow of Investment Advisory
              services submitted at the time of inspection we have highlighted
              that “As soon as client subscribes for the services his risk
              profiling is cross validated manually. As per details shared by
              client his risk profiling is carried out & communicated to client.
            </p>
          </div>
          <p className="pt-3">
            Tolerance for risk is a key consideration in determining your
            probable level of comfort with varying investing choices. Also, risk
            categorization helps to do the suitability assessment & suitable
            service selection for client.
          </p>
          <p className="fw-bold mb-3 pt-4">Risk Profiling Answered:</p>
          <table className="table-bordered w-100 text-center">
            <tbody>
              <tr>
                <td className="py-2">Client Name :</td>
                <td className="text-capitalize">{data.name}</td>
                <td>Risk Type :</td>
                <td className="text-capitalize">{data.risk_level} Client</td>
              </tr>
              <tr>
                <td className="py-2">Pan :</td>
                <td>{data.pan_number}</td>
                <td>Risk Score :</td>
                <td>{data.risk_score}</td>
              </tr>
            </tbody>
          </table>
          <table className="table-bordered w-100 my-5">
            <thead>
              <tr className="text-center">
                <th className="p-2" style={{ width: "60%" }}>
                  Question
                </th>
                <th className="p-2">Score</th>
              </tr>
            </thead>
            <tbody>
              {riskData.length > 0 ? (
                riskData.map((data) => (
                  <tr key={data[0]}>
                    <td className="p-1">{data[0]}</td>
                    <td className="p-1">{`${data[1]} - ( ${data[2]} score)`}</td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan={2}>No data found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="suitability-container">
          <h3 className="text-center fw-bold">SUITABILITY ASSESSMENT</h3>
          <p className="mb-0">Date: {currDate}</p>
          <p className="mb-0">
            Name: <span className="fw-bold">{data.name}</span>
          </p>
          <p className="mb-0">Email Id: {data.email}</p>
          <p className="mb-0">Mobile No: {data.mobile_number}</p>
          <div className="my-5">
            <h4 className="fw-bold">Process for Selecting Investments :</h4>
            <p>
              As per SECURITIES AND EXCHANGE BOARD OF INDIA (INVESTMENT
              ADVISERS) REGULATIONS, 2013 Investment adviser shall ensure that:
            </p>
            <ul>
              <li style={{ listStyleType: "square" }}>
                All investments on which investment advice is provided is
                appropriate to the risk profile of the client;
              </li>
              <li style={{ listStyleType: "square" }}>
                It has a documented process for selecting investments based on
                client’s investment objectives and financial situation;
              </li>
              <li style={{ listStyleType: "square" }}>
                It understands the nature and risks of products or assets
                selected for clients;
              </li>
              <li style={{ listStyleType: "square" }}>
                It has a reasonable basis for believing that a recommendation or
                transaction entered into:
              </li>
              <ul>
                <li style={{ listStyleType: "disc" }}>
                  a. meets the client’s investment objectives;
                </li>
                <li style={{ listStyleType: "disc" }}>
                  b. is such that the client is able to bear any related
                  investment risks consistent with its investment objectives and
                  risk tolerance;
                </li>
                <li style={{ listStyleType: "disc" }}>
                  c. is such that the client has the necessary experience and
                  knowledge to understand the risks involved in the transaction.
                </li>
              </ul>
            </ul>
          </div>
          <div className="my-5">
            <h4 className="fw-bold">
              Process for Suitability Assessment for client :
            </h4>
            <p>
              Pursuant to the SEBI (INVESTMENT ADVISERS) REGULATIONS, 2013
              guidelines with respect to Suitability Assessment, we do proper
              risk profiling and risk assessment for each of the clients. As per
              risk analysis & client requirement we ensure that correct
              product/service as per client risk tolerance capacity is being
              offered which is suitable for client. Also, client subscribes for
              any of our services after evaluating our service features,
              pricing, risk involved, scope of service, mode of service
              delivery, trading strategy, your risk appetite, disclaimer,
              disclosure, terms of usage, T&C & NO REFUND POLICY explicitly
              communicated to client in personal meeting, understanding & being
              aware of the risk involved in Stock / Commodity / Currency market
              trading.
            </p>
            <p>
              After assessing, being affirmative with the service suitability &
              being satisfied with the services offered, client willingly
              subscribe for the service/services keeping in check their
              financial standing and assessing the capacity to absorb losses,
              whenever incurred.
            </p>
            <p>
              To ensure that the advice offered to the client is suitable for
              the client we shall ensure that all investments on which
              investment advice is provided is appropriate to the risk profile
              of the client by categorizing client as low risk appetite, medium
              risk appetite and high risk appetite. A similar classification
              will be done for products/services after considering the risk and
              reward of the services offered and various SEBI guidelines in this
              regards. Client with Medium risk appetite is recommended to
              subscribe medium risk services only and client with high risk
              appetite is recommended to subscribe either medium or high risk
              services. We do not offer low risk services. Thus, it is ensured
              that advices which are being offered to clients are fully
              suitable.
            </p>
            <p>
              Basis upon the risk tolerance capacity & perspective of
              trading/investment in market with the horizon of profit returns
              from the market, investors/traders are classified as Conservative,
              Moderate & Aggressive.
            </p>
            <table className="table-bordered w-100 text-center">
              <thead>
                <tr>
                  <th className="p-2">Sr. No.</th>
                  <th>Type of Investor/Trader</th>
                  <th>Definition Investor/Trader</th>
                  <th>Type of products</th>
                  <th>Risk Tolerance Capacity</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-2">1</td>
                  <td>Conservative (Low Risk Category Client)</td>
                  <td className="p-1">
                    An investor who has low ability to accept risk. A
                    conservative investor is someone who wants his money to grow
                    but does not want to risk his principle investment.{" "}
                  </td>
                  <td>FD,Gold,PPF ,NSC, Savings bank </td>
                  <td>Low Risk</td>
                </tr>
                <tr>
                  <td className="p-2">2</td>
                  <td>Moderate (Moderate Risk Category Client)</td>
                  <td className="p-1">
                    An investor values reducing risks and enhancing returns
                    equally. This investor is willing to accept short to long
                    term fluctuation risks to seek higher returns & capital
                    gain. A Moderate investor endures a short-term loss of
                    principal and aware that capital may fall under original
                    investment capital, during economy downturn. Group 1 /
                    Moderate Risk Category services are offered.
                  </td>
                  <td>
                    Equity, Mutual Funds, Real Estate, small/Large Cap stocks,
                    High income Bonds
                  </td>
                  <td>Medium Risk</td>
                </tr>
                <tr>
                  <td className="p-2">3</td>
                  <td>Aggressive (High Risk Category Client)</td>
                  <td className="p-1">
                    An investor who seek for “high risk high return” investment,
                    able to accept short to long term fluctuation to earn very
                    high returns. Services under Group 2 / High Risk Category
                    Services are recommended however client may also opt for
                    services under Group 1/ Moderate Risk Category Services.
                  </td>
                  <td>Derivatives ,Currency, Futures and Options</td>
                  <td>High Risk</td>
                </tr>
              </tbody>
            </table>
            <div className="mt-3">
              <p className="mb-0">
                Name: <span className="fw-bold">{data.name}</span>
              </p>
              <p className="mb-0">
                Date: <span className="fw-bold">{currDate}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="agreement-container pt-3">
          <h4 className="fw-bold text-center">INVESTMENT ADVISOR AGREEMENT</h4>
          <h5
            className="fw-bold text-center"
            style={{ textDecoration: "underline" }}
          >
            Schedule A: The Client
          </h5>
          <table className="table-bordered w-50 text-center mx-auto mt-3">
            <thead>
              <tr>
                <td></td>
                <td className="fw-bold p-2">The Client</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-2">Mr./Mrs.</td>
                <td className="fw-bold">{data.name}</td>
              </tr>
              <tr>
                <td className="p-2">S/O / D/O Mr.</td>
                <td className="fw-bold">{data.father_name}</td>
              </tr>
              <tr>
                <td className="p-2">Mobile</td>
                <td className="fw-bold">{data.mobile_number}</td>
              </tr>
              <tr>
                <td className="p-2">Email Id</td>
                <td className="fw-bold">{data.email}</td>
              </tr>
              <tr>
                <td className="p-2">Address</td>
                <td className="fw-bold">{data.address}</td>
              </tr>
              <tr>
                <td className="p-2">PAN</td>
                <td className="fw-bold">{data.pan_number}</td>
              </tr>
              <tr>
                <td className="p-2">KYC No</td>
                <td className="fw-bold">{data.ckyc_number}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="Schedule-B-container my-4">
          <h5
            className="fw-bold text-center"
            style={{ textDecoration: "underline" }}
          >
            Schedule B: Agreement for Investment Advisory Services
          </h5>
          <p className="text-center pt-4">
            <span className="fw-bold">
              THIS INVESTMENT ADVISORY SERVICES AGREEMENT
            </span>{" "}
            (the <span className="fw-bold">“Agreement”</span>) is made on
            {currDate}
          </p>
          <p className="text-center fw-bold py-2">BETWEEN</p>
          <p className="text-center">
            M/s Moneydhan Investment Advisory India LLP, a registered investment
            adviser with SEBI Registration No. INA000018984 having office 7/433,
            Alumavunchodu, Thankalam, Kothamangalam,KOCHI,Ernakulam,KERALA,INDIA
            – 686691 (hereinafter referred to as the{" "}
            <span className="fw-bold">“INVESTMENT ADVISER”</span> which
            expression shall unless excluded by or repugnant to the context, be
            deemed to include its administrators and permitted assigns) of the{" "}
            <span className="fw-bold">FIRST PART</span>
          </p>
          <p className="text-center fw-bold py-2">AND</p>
          <p className="text-center">
            Person/s specified in the Schedule A as “the Client” [hereinafter
            called “Client” which expression shall, unless repugnant to the
            context or meaning thereof, be deemed to mean and include (where the
            Client is a Company or a body corporate) its successors and
            permitted assigns; of the{" "}
            <span className="fw-bold">SECOND PART;</span>
          </p>
          <p className="fw-bold py-2">
            Both INVESTMENT ADVISER and the CLIENT shall also hereinafter
            individually refer to as Party & collectively as Parties.
          </p>
          <p>
            Whereas the CLIENT is desirous of availing the advisory services in
            respect of the analysis of the investment portfolio from the
            Investment Adviser on the terms & conditions as described
            hereinafter.
          </p>
          <div className="py-2">
            <h4 className="fw-bold">
              1. APPOINTMENT OF THE INVESTMENT ADVISER:
            </h4>
            <p className="mb-0">
              1.1 In accordance with the applicable laws, Client hereby
              appoints, entirely at his / her / its risk, the Investment Adviser
              to provide the required services in accordance with the terms and
              conditions of the agreement as mandated under Regulation19(1)(d)
              of the Securities and Exchange Board of India (Investment
              Advisers) Regulations, 2013.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">2. CONSENT OF THE CLIENT:</h4>
            <p className="mb-0">
              2.1 “I / We have read and understood the terms and conditions of
              Investment Advisery services provided by the Investment Adviser
              along with the fee structure and mechanism for charging and
              payment of fee.”
            </p>
            <p className="mb-0">
              2.2 “Based on our written request to the Investment Adviser, an
              opportunity was provided by the Investment Adviser to ask
              questions and interact with ‘person(s) associated with the
              investment advice”.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">
              3. DECLARATION FROM THE INVESTMENT ADVISER:
            </h4>
            <p className="mb-0">
              3.1 Investment Adviser shall neither render any investment advice
              nor charge any fee until the client has signed this agreement.
            </p>
            <p className="mb-0">
              3.2 Investment Adviser shall not manage funds and securities on
              behalf of the client and that it shall only receive such sums of
              monies from the client as are necessary to discharge the client’s
              liability towards fees owed to the Investment Adviser.
            </p>
            <p className="mb-0">
              3.3 Investment Adviser shall not, in the course of performing its
              services to the client, hold out any investment advice implying
              any assured returns or minimum returns or target return or
              percentage accuracy or service provision till achievement of
              target returns or any other nomenclature that gives the impression
              to the client that the investment advice is risk-free and/or not
              susceptible to market risks and or that it can generate returns
              with any level of assurance.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">
              4. FEES SPECIFIED UNDER INVESTMENT ADVISER REGULATIONS AND
              RELEVANT CIRCULARS ISSUED THEREUNDER:
            </h4>
            <p>
              4.1 Regulation 15 A of the amended Investment Adviser Regulations
              provide that Investment Advisers shall be entitled to charge fees
              from a client in the manner as specified by SEBI, accordingly
              Investment Advisers shall charge fees from the clients in either
              of the two modes:
            </p>
            <h5>(A) Assets under Advice (AUA) mode</h5>
            <p className="mb-0">
              a) The maximum fees that may be charged under this mode shall not
              exceed 2.5 percent of AUA per annum per client across all services
              offered by IA.
            </p>
            <p className="mb-0">
              {" "}
              b) IA shall be required to demonstrate AUA with supporting
              documents like demat statements, unit statements etc. of the
              client.
            </p>
            <p>
              c) Any portion of AUA held by the client under any pre-existing
              distribution arrangement with any entity shall be deducted from
              AUA for the purpose of charging fee by the IA.
            </p>
            <h5>(B) Fixed fee mode</h5>
            <p className="mb-0">
              The maximum fees that may be charged under this mode shall not
              exceed INR 1,25,000 per annum per client across all services
              offered by IA.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">
              5. FEES APPLICABLE TO THE CLIENT AND BILLING:
            </h4>
            <p className="mb-0">
              5.1 Applicable fee for advisery services is as mentioned at
              Schedule F
            </p>
            <p className="mb-0">
              5.2 The payment of fees shall be through any mode which shows
              traceability of funds. Such modes may include account payee
              crossed cheque/ Demand Drafts or by way of direct credit to the
              bank accounts through NEFT/ RTGS/ IMPS/ UPI or any other mode
              specified by SEBI from time to time. However, the fees shall not
              be in cash.
            </p>
            <p className="mb-0">
              5.3 Investment Adviser shall receive all considerations by way of
              remuneration or compensation or in any other form from the client
              only and not from any person other than the client being advised,
              in respect of the underlying securities or investment products for
              which the adviceis or to be provided.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">6. SCOPE OF SERVICE:</h4>
            <p className="mb-0">
              6.1 Investment Adviser may provide some or all of the following
              services to the Client:
            </p>
            <p className="mb-0">
              6.1.1 Assist in articulating the family goals & objectives.
            </p>
            <p className="mb-0">6.1.2 Assist in assessing the risk profile.</p>
            <p className="mb-0">
              6.1.3 Develop an Investment Policy Statement based on your goals
              and risk profile.
            </p>
            <p className="mb-0">
              6.1.4 Develop investment recommendations based on goals & risk
              profile and assist in implementing these recommendations.
            </p>
            <p className="mb-0">
              6.1.5 Provide advice with respect to the investment in equity,
              mutual funds, insurance, commodities and other financial assets,
              as needed, and assist in implementing recommendations.
            </p>
            <p className="mb-0">
              6.1.6 Provide advice and recommendations in any other areas of
              financial planning in which Adviser or Client identifies a need.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">
              7. DUTIES & FUNCTIONS OF THE INVESTMENT ADVISER:
            </h4>
            <p className="mb-0">
              7.1 Investment Adviser shall provide Investment Advisory Services
              to the Client during the term of this Agreement on investment in
              all financial/investment products under all regulated authorities
              as is permitted under applicable laws and regulations governing
              Investment Adviser & the financial industry. The services rendered
              by the Investment Adviser are non- binding non- recourse advisory
              in nature and the final decision on the type of instruments; the
              proportion of exposure and tenure of the investments shall be
              taken by the Client at its discretion.
            </p>
            <p className="mb-0">
              7.2 Investment Adviser shall act in a fiduciary capacity as one of
              the advisors to the Client with respect to managing its
              investment-related portfolio holistically & will be providing all
              back end supporting services. Investment Adviser shall act in a
              bonafide manner for the benefit and in the interest of the Client.
            </p>
            <p className="mb-0">
              7.3 Investment Adviser shall be in compliance with the SEBI
              (Investment Advisers) Regulations, 2013 and its amendments, rules,
              circulars and notifications.
            </p>
            <p className="mb-0">
              7.4 Investment Adviser shall be in compliance with the eligibility
              criteria as specified under the IA Regulations at all times.
            </p>
            <p className="mb-0">
              7.5 Pursuant to the SEBI (INVESTMENT ADVISERS) REGULATIONS, 2013
              guidelines with respect to Risk Profiling and Suitability
              Assessment, Investment Adviser shall conduct proper risk profiling
              and risk assessment for each of the clients. As per risk analysis,
              risk capacity, risk aversion & client requirement, the Investment
              Adviser needs to ensure that correct product/service as per client
              risk tolerance capacity is being offered, which is suitable for
              client.
            </p>
            <p className="mb-0">
              7.6 Investment Adviser shall provide reports to clients on
              potential and current investments if requested.
            </p>
            <p className="mb-0">
              7.7 Investment Adviser shall maintain client-wise KYC, advice,
              risk assessment, analysis reports of investment advice and
              suitability, terms and conditions document, rationale of advice,
              related books of accounts and a register containing list of
              clients along with dated investment advice in compliance with the
              SEBI (Investment Advisers) Regulations, 2013.
            </p>
            <p className="mb-0">
              7.8 Investment Adviser shall get annual compliance audit conducted
              as per the SEBI (Investment Advisers) Regulations, 2013.
            </p>
            <p className="mb-0">
              7.9 Investment Adviser undertakes to abide by the Code of Conduct
              as specified in the Third Schedule of the SEBI (Investment
              Advisers) Regulations, 2013. Investment Adviser shall not receive
              any consideration in any form, if the client desires to avail the
              services of intermediary recommended by Investment Adviser.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">8. INVESTMENT OBJECTIVE AND GUIDELINES:</h4>
            <p className="mb-0">
              8.1 Investment Adviser would provide investment advice with
              respect to investment in equity, mutual funds, insurance,
              commodities and other financial assets, as needed.
            </p>
            <p className="mb-0">
              8.2 Investment Adviser undertakes to recommend direct
              implementation of advice i.e. through direct schemes/ direct
              codes, and other client specifications / restrictions on
              investments, if any.
            </p>
            <p className="mb-0">
              8.3 Investment Adviser shall provide investment advice based on
              the risk profiling conducted for the client, total budgeted
              investment amount of the client and time period for deployment as
              informed by the client.
            </p>
            <p className="mb-0">
              8.4 Investment Adviser shall communicate the tax related aspects
              pertaining to investment advice and as applicable on the
              investment adviser’s fee, if any.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">9. RISK FACTORS:</h4>
            <p className="mb-0">
              9.1 Investments in securities are subject to market risks and
              there is no assurance or guarantee that the objective of the
              investments will be achieved;
            </p>
            <p className="mb-0">
              9.2 Past performance of the investment adviser does not indicate
              its future performance.
            </p>
            <p className="mb-0">
              9.3 The performance of the investments/products may be affected by
              changes in Government policies, general levels of interest rates
              and risks associated with trading volumes, liquidity and
              settlement systems in equity and debt markets.
            </p>
            <p className="mb-0">
              9.4 Investments in the products which the Clients have opted are
              subject to wide range of risks which inter alia also include but
              not limited to economic slowdown, volatility & illiquidity of the
              stocks, poor corporate performance, economic policies, changes of
              Government and its policies, acts of God, acts of war, civil
              disturbance, sovereign action and /or such other acts/
              circumstance beyond the control of Investment Adviser or any of
              its Associates.
            </p>
            <p className="mb-0">
              9.5 The names of the products/nature of investments do not in any
              manner indicate their prospects or returns. The performance in the
              equity may be adversely affected by the performance of individual
              companies, changes in the market place and industry specific and
              macro-economic factors.
            </p>
            <p className="mb-0">
              9.6 Investments in debt instruments and other fixed income
              securities are subject to default risk, liquidity risk and
              interest rate risk. Interest rate risk results from changes in
              demand and supply for money and other macroeconomic factors and
              creates price changes in the value of the debt instruments.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">10. VALIDITY OF ADVISERY SERVICES:</h4>
            <p className="mb-0">
              10.1 The validity of this agreement starts from the date of
              signing and will continue to be in force till any of the parties
              terminate it by giving 1 months’ notice period.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">11. AMENDMENTS:</h4>
            <p className="mb-0">
              11.1 The Investment Adviser and the client shall be entitled to
              make amendments to this agreement after mutual agreement. This
              Agreement may be amended or revised only by an instrument endorsed
              by the Client and by Investment Adviser.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">12. TERMINATION:</h4>
            <p className="mb-0">
              12.1 This Agreement may be terminated under the following
              circumstances, namely-
            </p>
            <p className="mb-0">
              (a) Voluntary / mandatory termination by the Investment Adviser.
            </p>
            <p className="mb-0">
              (b) Voluntary / mandatory termination by the client.
            </p>
            <p className="mb-0">
              (c) Suspension/Cancellation of registration of Investment Adviser
              by SEBI.
            </p>
            <p className="mb-0">
              (d) Any other action taken by other regulatory body/ Government
              authority.
            </p>
            <p className="mb-0">
              12.2 In case of a voluntary termination of the agreement, the
              client would be required to give a 30 days prior written notice
              while the Investment Adviser would be required to give a 30 days
              prior written notice.
            </p>
            <p className="mb-0">
              12.3 In case of suspension of the certificate of registration of
              the IA, the client may terminate the agreement.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">
              13. IMPLICATIONS OF AMENDMENTS AND TERMINATION:
            </h4>
            <p className="mb-0">
              13.1 Notwithstanding any such termination, all rights, liabilities
              and obligations of the parties arising out of or in respect of
              transactions entered into prior to the termination of this
              relationship shall continue to subsist and vest in/be binding on
              the respective parties or his/its respective heirs, executors,
              administrators, legal representatives or successors, as the case
              may be;
            </p>
            <p className="mb-0">
              13.2 In case the clients are not satisfied with the services being
              provided by the investment adviser and want to terminate/ stop
              Investment Advisory services or the investor adviser is unable to
              provide Investment Advisory services, either party shall have a
              right to terminate Investment Advisory relationship at any time
              subject to refund of advisory fee after deducting one quarters fee
              as breakage fee in case termination is initiated by the clients
              and refund of the proportionate advisory fee in case termination
              is initiated by Investment Adviser.
            </p>
            <p className="mb-0">
              13.3 The Investment Adviser would provide transition support, if
              requested, to the client in the event of termination.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">14. RELATIONSHIP WITH RELATED PARTIES:</h4>
            <p className="mb-0">
              14.1 Investment Adviser is carrying on its activities
              independently, at an arm's-length basis from all other activities.
            </p>
            <p className="mb-0">
              14.2 Investment Adviser does not have any conflict of interest of
              the investment advisory activities with its relationship with
              related parties, such conflict of interest shall be disclosed to
              the client as and when they arise.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">
              15. INVESTMENT ADVISER ENGAGED IN OTHER ACTIVITIES:
            </h4>
            <p className="mb-0">
              15.1 Investment Adviser maintains an arms-length relationship
              between its activities as an investment adviser and other
              activities and shall ensure that this arm’s length relationship
              would be maintained throughout the tenure of advisory service
            </p>
            <p className="mb-0">
              15.2 Investment Adviser shall not provide any distribution
              services, for securities and investment products, either directly
              or through their group to an advisory client.
            </p>
            <p className="mb-0">
              15.3 Investment Adviser shall not provide investment advisory
              services, for securities and investment products, either directly
              or through their group to the distribution client;
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">16. REPRESENTATION TO CLIENT:</h4>
            <p className="mb-0">
              16.1 Investment Adviser shall ensure that it will take all
              consents and permissions from the client prior to undertaking any
              actions in relation to the securities or investment product
              advised by the investment adviser.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">17. NO RIGHT TO SEEK POWER OF ATTORNEY:</h4>
            <p className="mb-0">
              17.1 The Investment Adviser shall not seek any power of attorney
              or authorizations from its clients for implementation of
              investment advice.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">18. NO CONFLICT OF INTEREST:</h4>
            <p className="mb-0">
              18.1 Investment Adviser does not have any conflict of interest of
              the investment advisory activities, such conflict of interest
              shall be disclosed to the client as and when they arise.
            </p>
            <p className="mb-0">
              18.2 Investment adviser shall not derive any direct or indirect
              benefit out of the client’s securities/investment products.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">
              19. MAINTENANCE OF ACCOUNTS AND CONFIDENTIALITY:
            </h4>
            <p className="mb-0">
              19.1 Investment Adviser shall be responsible for maintenance of
              client accounts and data as mandated under the SEBI (Investment
              Advisers) Regulations, 2013.
            </p>
            <p className="mb-0">
              19.2 Investment Adviser shall not divulge any confidential
              information about its client, which has come to its knowledge,
              without taking prior permission of its client, except where such
              disclosures are required to be made in compliance with any law for
              the time being in force.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">20. LIABILITY OF INVESTMENT ADVISER:</h4>
            <p className="mb-0">
              20.1 Investment Adviser shall not incur any liability by reason of
              any loss, which a client may suffer by reason of any depletion in
              the value of the assets under advice, which may result by reason
              of fluctuation in asset value, or by reason of non-performance or
              under-performance of the securities/funds or any other market
              conditions.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">21. REPRESENTATIONS AND COVENANTS:</h4>
            <p>
              21.1 Details of name, contact no, email id , certification and
              qualifications of the principal officer
            </p>
            <p className="fw-bold">Principal Officer/Adviser</p>
            <table className="table-bordered w-100 text-center mb-3">
              <thead>
                <tr>
                  <td className="fw-bold p-2">Name</td>
                  <td className="fw-bold">Contact No.</td>
                  <td className="fw-bold">Email</td>
                  <td className="fw-bold">Certification</td>
                  <td className="fw-bold">Qualification</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-2">Mr. Hardeep Shantaram Korde</td>
                  <td>91 96049 01686</td>
                  <td>moneydhanadvisor@gmail.com</td>
                  <td>NISM XA and XB</td>
                  <td>MA Economics</td>
                </tr>
              </tbody>
            </table>
            <p className="mb-0">
              21.2 Investment Adviser is registered with SEBI as Investment
              Adviser with Registration No. INA000018984. The investment adviser
              got its registration on Sep 30, 2021 and is engaged in advisory
              services as approved under SEBI (Investment Advisers) Regulations,
              2013.
            </p>
            <p className="mb-0">
              21.3 Investment Adviser shall ensure that the adviser, principal
              officer and persons associated with the investment advice,
              maintains the qualification and certification throughout the
              validity of advisory service.
            </p>
            <p className="mb-0">
              21.4 Investment Adviser shall ensure that the approvals and
              consents as mentioned in clause 21.1 & 21.2 remains valid
              throughout the advisory service.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">22. DEATH OR DISABILITY OF CLIENT:</h4>
            <p className="mb-0">
              22.1 The death or incapacity of the Client shall not terminate the
              authority of Investment Adviser granted herein until Investment
              Adviser receives actual notice of such death or incapacity. Upon
              such notice client’s executor, guardian, successor, nominee,
              attorney-in-fact or other authorized representative must engage
              Investment Adviser in order to continue to service client’s
              accounts.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">23. NOTICE:</h4>
            <p className="mb-0">
              23.1 Any notice or other communication provided for in this
              Agreement shall be in writing, to the aforesaid address and shall
              be transmitted (a) by postage prepaid registered mail or by
              courier service or (b) email id, as elected by the Party giving
              such notice to the fax.
            </p>
            <p className="fw-bold mb-0">A. Client</p>
            <p className="fw-bold ps-3 mb-0">Name: {data.name}</p>
            <p className="fw-bold ps-3 mb-0">Email Id: {data.email}</p>
            <p className="fw-bold mb-0">B. Advisor</p>
            <p className="fw-bold ps-3 mb-0">
              Name: M/s Moneydhan Investment Advisory India LLP
            </p>
            <p className="fw-bold ps-3 mb-0">
              Email id:moneydhanadvisor@gmail.com
            </p>
            <p className="mb-0">
              23.2 All notices or other communications shall be deemed to have
              been validly given on:
            </p>
            <p className="mb-0">
              a) the date 10 (Ten) days after posting if transmitted by airmail,
              or (b) the date of receipt if transmitted by courier, fax and
              email.
            </p>
            <p className="mb-0">
              b) Either Party may, from time to time, change their address or
              representative for receipt of notices or other communications
              provided for in this Agreement by giving to the other Party not
              less than 10 (Ten) days prior written notice to that effect.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">
              24. SETTLEMENT OF DISPUTES AND PROVISION FOR ARBITRATION:
            </h4>
            <p className="mb-0">
              24.1 Investment Adviser strives to provide completely ethical,
              unbiased and expert services which would help the client to meet
              their personal and financial goals.
            </p>
            <p className="mb-0">
              24.2 Investment Adviser shall take utmost care in ensuring the
              accuracy of services provided to you. Plans, reports and any other
              content are thoroughly checked before being released to the
              Client. However, in case of any Service quality issues, clients
              are free to take the following recourse:
            </p>
            <p className="mb-0 ps-2">
              24.2.1 Please send your comments or queries or complaints:
            </p>
            <p className="mb-0 ps-3">
              <span className="fw-bold pe-1">Email to</span>
              moneydhancompliance@gmail.com or Sujith@moneydhan.com
            </p>
            <p className="mb-0 ps-3">
              <span className="fw-bold pe-1">Send letter to</span>
              M/s Moneydhan Investment Advisory India LLP
            </p>
            <p className="mb-0 ps-3">
              7/433, Alumavunchodu
              <br /> Thankalam
              <br /> Kothamangalam
              <br /> Kochi
              <br /> Ernakulam
              <br />
              KERALA
              <br /> INDIA
              <br /> 686691
            </p>
            <p className="mb-0">
              24.3 No suit, prosecution or other legal proceeding shall lie
              against the <span className="fw-bold">Investment adviser</span>{" "}
              for any damage caused or <span className="fw-bold">likely</span>{" "}
              to be caused by anything which is done in{" "}
              <span className="fw-bold">good faith</span> or intended to be{" "}
              <span className="fw-bold">done</span> under the provisions of the
              Securities and Exchange Board of India (Investment Advisers)
              Regulations, 2013.
            </p>
            <p className="mb-0">
              24.4 This Agreement is subject to the rules and regulations as are
              or may be framed/ issued by the Central Government, the Reserve
              Bank of lndia (RBI), SEBI and/or any other competent authority,
              from time to time.
            </p>
            <p className="mb-0">
              24.5 All disputes, differences, claims and questions whatsoever
              arising from this Agreement between the Client and Investment
              Adviser and/or their respective representatives touching these
              presents shall be in accordance with and subject to the provisions
              of The Arbitration and Conciliation Act, 1996, or any statutory
              modification or re-enactment thereof for the time being in force.
              Such Arbitration proceedings shall be held at{" "}
              <span className="fw-bold">Bangalore</span> and the language of
              Arbitration will be English.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">
              25. ADHERENCE TO GRIEVANCE REDRESSAL TIMELINES:
            </h4>
            <p className="mb-0">
              25.1 Investment Adviser shall be responsible to resolve the
              grievances within the timelines specified under SEBI circulars.
            </p>
            <p className="mb-0">
              25.2 Both parties agree to undertake online conciliation and/or
              online abritration by participating in the ODR Portal and/or
              undertaking dispute resolution in the manner specified in the SEBI
              cercular No. SEBI/HO/OIAE/OIAE_IAD_!/P/CIR/2023/131 dated july
              31,2023,on "Online Resolution of Disputes in the indian Securities
              Market".
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">22. DEATH OR DISABILITY OF CLIENT:</h4>
            <p className="mb-0">
              22.1 The death or incapacity of the Client shall not terminate the
              authority of Investment Adviser granted herein until Investment
              Adviser receives actual notice of such death or incapacity. Upon
              such notice client’s executor, guardian, successor, nominee,
              attorney-in-fact or other authorized representative must engage
              Investment Adviser in order to continue to service client’s
              accounts.
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">26. SEVERABILITY:</h4>
            <p className="mb-0">
              26.1 If any provision of this Agreement shall be held or made
              invalid by a court decision, statute, rule or otherwise, the
              remainder of this Agreement shall not be affected thereby
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">27. FORCE MAJEURE:</h4>
            <p className="mb-0">
              27.1 The Investment Adviser shall not be liable for delays or
              errors occurring by reason of circumstances beyond its control,
              including but not limited to acts of civil or military authority,
              national emergencies, work stoppages, fire, flood, catastrophe,
              acts of God, insurrection, war, riot, or failure of communication
              or power supply.
            </p>
            <p className="mb-0">
              27.2 In the event of equipment breakdowns beyond its control, the
              Investment Adviser shall take reasonable steps to minimize service
              interruptions but shall have no liability with respect thereto
            </p>
          </div>
          <div className="py-2">
            <h4 className="fw-bold">28. MISCELLANEOUS:</h4>
            <p className="mb-0">
              28.1 Client shall not assign, convey or otherwise transfer any of
              its rights, obligations, or interest herein without the prior
              written consent of Investment Adviser, however Investment Adviser
              may depending on the prevailing situation assign, convey or
              otherwise transfer any of its rights, obligations, or interest by
              duly, informing clients.
            </p>
            <p className="mb-0">
              28.2 Without prejudice to the other rights or remedies that
              Investment Adviser may have under the applicable laws, the Client
              hereby agrees to indemnify Investment Advisor, its directors,
              employees and representatives for all the liabilities (including
              claims, damages, suits or legal expenses in defending itself in
              relation to the foregoing) arising due to non-performance and/or
              non-observance of your duties and obligations under this Agreement
              or due to breach of terms and conditions provided herein. The
              provisions of this clause shall survive termination of this
              Agreement for any reason whatsoever.
            </p>
            <p className="mb-0">
              28.3 Whenever the Client receives any communication (including
              investment advice) pursuant to this Agreement from the Investment
              Adviser, the Client agree it will inform Investment Adviser of any
              mistakes or omission or disagreements within Five (5) days from
              the date of the relevant information/documents. If the Client
              fails to do so, the Client shall be deemed to have agreed to the
              contents in such communications (including investment advice) and
              will no longer have the right to dispute the accuracy thereof.
              Accordingly, Investment Adviser has the right to treat the
              Client’s silence as Client’s representation that such
              communication is accurate or/and to the Client’s satisfaction.
              Nothing in this Clause shall prevent the Investment Adviser from
              unilaterally amending any such statement for any inaccuracy it
              detects.
            </p>
            <p className="mb-0">
              28.4 Investment Adviser reserves the right to amend, modify or
              vary any of the terms stated herein at any time in its sole and
              absolute discretion and any such amendment, modification or
              variation shall take into effect and bind the Client from such
              date as may be prescribed by the Investment Adviser.
            </p>
            <p className="mb-0">
              28.5 The advice and recommendations given to the Client are
              intended strictly for the Client benefit, and no other persons
              shall be entitled to rely on such information.
            </p>
            <p className="mb-0">
              28.6 No failure on the part of any Party to exercise, and no delay
              in exercising, any right or remedy under this Agreement will
              operate as a waiver thereof nor will any single or partial
              exercise of any right or remedy preclude any other or further
              exercise thereof or the exercise of any other right or remedy. The
              rights and remedies provided in this Agreement are cumulative and
              not exclusive of any rights or remedies provided by law.
            </p>
            <p className="mb-0">
              28.7 The illegality, invalidity or unenforceability of any
              provision of this Agreement under the laws of any jurisdiction
              shall not affect its legality, validity or enforceability under
              the laws of any other jurisdiction nor the legality, validity or
              enforceability of any other provision.
            </p>
            <p className="mb-0">
              28.8 Other than as specifically permitted under this Agreement,
              the Client shall not publish, disseminate or broadcast
              advertisements, circulars or other publicity material referring to
              the Investment Adviser without the prior consent of Investment
              Adviser.
            </p>
            <p className="mb-0">
              28.9 Neither the Investment Adviser nor any of its associated
              persons are qualified to nor shall they render any legal or
              accounting advice. The Client acknowledges and understands that
              Investment Adviser does not and will not practice law or
              accounting in providing advice to the Client and that none of the
              sums paid under this Agreement relate to accounting or legal
              services. Further, the Client is advised to discuss the
              recommendations of the Investment Adviser with the Client`s
              attorney and accountant. The Client understands that if such
              services are necessary, it shall be the responsibility of the
              Client to obtain such services. Also any Recommendation or advice
              will be in accordance with investment objectives conveyed by the
              Client to the Investment Adviser. The Client understands that
              investments carry differing levels of risks which may result in
              loss of value and that past performance of investments recommended
              by the Adviser should not be construed as an indication of future
              results. The Client also understands that the Investment Advisor`s
              advice may differ from client to client based on the individual
              time horizon, risk tolerance, financial situation of each client
              and other subjective criteria of each of the Adviser`s clients
            </p>
            <p className="mb-0">
              28.10 Nothing herein contained shall be deemed to create or
              constitute a partnership between the Parties hereto. This
              Agreement may only be varied with the written agreement of both
              Parties. This Agreement may be entered into in any number of
              counterparts, each of which when executed and delivered shall be
              an original.
            </p>
            <p className="mb-0">
              28.11 Each party agrees to perform such further actions and
              execute such further agreements as are necessary to effectuate the
              purposes hereof.
            </p>
          </div>
        </div>
        <div className="Schedule-C-container my-4">
          <h4
            className="text-center fw-bold"
            style={{ textDecoration: "underline" }}
          >
            Schedule C: Fee Schedule
          </h4>
          <table className="table-bordered text-center w-100 mt-5">
            <thead>
              <tr>
                <td className="fw-bold p-2">Mode</td>
                <td className="fw-bold">Asset under Advisory (AUA)</td>
                <td className="fw-bold">Applicable Fee (Annual) </td>
                <td className="fw-bold">Accepted Mode</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-2">1</td>
                <td>Below Rs. 1 Crore</td>
                <td>Fixed Fee- 1% of AUA</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td className="p-2">1</td>
                <td>Rs. 1 Crore and above</td>
                <td>
                  Annual Fee- 2.5% of AUA (Payable in breakup of 4 equal
                  Quarterly installments each paid at the beginning of quarter.){" "}
                </td>
                <td>N/A</td>
              </tr>
            </tbody>
          </table>
          <div className="mt-3">
            <p className="fw-bold mb-0">Terms:</p>
            <p className="mb-0">
              1. Fixed Fee is payable at the beginning of annual period.
            </p>
            <p className="mb-0">
              2. Invoice would be generated on due dates, which is to be cleared
              within 15 days of invoice date.
            </p>
            <p className="mb-0">3. Illustration(s)</p>
          </div>
          <div className="mt-3">
            <p className="mb-0 fw-bold">For AUA- Above Rs 1 Crore :</p>
            <p className="mb-0">
              AUA* 2,5/100 i.e. if AUA is Rs. 10,00,00,000 the fees would be Rs.
              25,00,000/- (Rs.6,25,000/- payable quarterly at the beginning of
              each quarter)
            </p>
          </div>
        </div>
        <div className="fees-aplicable">
          <h4 className="fw-bold">FEES APPLICABLE TO THE CLIENT AND BILLING</h4>
          <p className="mb-3 fw-bold">
            Details of Service and Fees to be charged are following:
          </p>
        </div>
        <table className="table-bordered w-50 text-center mb-4">
          <thead>
            <tr>
              <td className="fw-bold p-2">Service Subscription</td>
              <td className="fw-bold">1% on AUA</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Validity of advisory services/ Duration </td>
              <td className="fw-bold p-2">1 year</td>
            </tr>
            <tr>
              <td>Current Risk Appetite </td>
              <td className="fw-bold p-2 text-capitalize">{data.risk_level}</td>
            </tr>
            <tr>
              <td>Fee to be charged </td>
              <td className="fw-bold p-2">1% on AUA</td>
            </tr>
            <tr>
              <td>Service Start date</td>
              <td className="fw-bold p-2">{currDate}</td>
            </tr>
            <tr>
              <td>Service End Date</td>
              <td className="fw-bold p-2">{endDate}</td>
            </tr>
          </tbody>
        </table>
        <div className="mt-4">
          <p>
            To,
            <br />
            <span className="fw-bold">
              MONEYDHAN INVESTMENT ADVISORY INDIA LLP
            </span>
            <br /> 7/433,
            <br /> Alumavunchodu,
            <br />
            Thankalam,
            <br /> Kothamangalam,
            <br /> Kochi,
            <br /> Ernakulam,
            <br /> KERALA,
            <br /> INDIA – 686691
            <br />
            Dear Sir / Madam
            <br /> We have opened an advisory account with you and we note that
            as a market intermediary, you are responsible for the updation of
            the KRA. We would like to bring to you, that you are requested to
            not change the email id mentioned in the KRA documents.
          </p>
          <p>
            Please use the email id: {data.email} which we have shared with you
            for the purpose of business here. We request you to note that email
            id: {data.email} is only for all the business and other
            transactional correspondence.
          </p>
          <p>Thanking you</p>
          <p>
            I/We hereby acknowledge that I/We have read and understood the terms
            and conditions of the Investment advisor agreement, fee structure,
            disclosure, Conflict of interest, & other documents executed between
            me/us and{" "}
            <span className="fw-bold">
              Moneydhan Investment Advisory India LLP
            </span>{" "}
            for availing the Investment Advisor services rendered by the Company
            and received a copy of the abovementioned documents.
          </p>
          <p className="mb-0">
            Name : <span className="fw-bold">{data.name}</span>
          </p>
          <p className="mb-0">
            Date : <span className="fw-bold">{currDate}</span>
          </p>
        </div>
        <div className="mb-5">
          <div className="d-flex align-items-center my-4">
            <input
              type="checkbox"
              id="agreement-checkbox"
              name="agreement-checkbox"
              value={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              required
            />
            <p style={{ color: "#6856B5" }} className="mb-0 ps-2">
              I Agree to all
              <a href="/#/terms-and-condition" target="_blank">
                {" "}
                Terms & Conditions{" "}
              </a>
              mentioned Above.
            </p>
          </div>
          <div className="text-center">
            <button
              className={
                isChecked
                  ? "btn btn-lg btn-primary"
                  : "btn btn-lg btn-secondary disabled"
              }
              onClick={eSign}
            >
              Sign the Agreement
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Esign;
