const TermsAndCondition = () => {
  return (
    <div className="p-3">
      <h4>Moneydhan Investment Advisor Terms of Service</h4>
      <p className="mt-3">
        <span className="fw-bold">1. Terms</span> <br /> By accessing the
        website at{" "}
        <a href="/https://www.Moneydhan.com" target="_blank">
          https://www.Moneydhan.com
        </a>
        , you are agreeing to be bound by these terms of service, all applicable
        laws and regulations, and agree that you are responsible for compliance
        with any applicable local laws. If you do not agree with any of these
        terms, you are prohibited from using or accessing this site. The
        materials contained in this website are protected by applicable
        copyright and trademark law.
      </p>
      <p className="mt-3">
        <span className="fw-bold">2. Use License</span>
        <br /> Permission is granted to temporarily download one copy of the
        materials (information or software) on Moneydhan Investment Advisor’s
        website for personal, non-commercial transitory viewing only. This is
        the grant of a license, not a transfer of title, and under this license
        you may not:
      </p>
      <p>
        modify or copy the materials;
        <br /> use the materials for any commercial purpose, or for any public
        display (commercial or non- commercial);
        <br /> attempt to decompile or reverse engineer any software contained
        on Moneydhan Investment Advisor’s website;
        <br /> remove any copyright or other proprietary notations from the
        materials; or transfer the materials to another person or “mirror” the
        materials on any other server.
        <br /> This license shall automatically terminate if you violate any of
        these restrictions and may be terminated by Moneydhan Investment Advisor
        at any time. Upon terminating your viewing of these materials or upon
        the termination of this license, you must destroy any downloaded
        materials in your possession whether in electronic or printed format.
      </p>
      <p className="mt-3">
        <span className="fw-bold">3. Disclaimer</span>
        <br /> The materials on Moneydhan Investment Advisor’s website are
        provided on an ‘as is’ basis. Moneydhan Investment Advisor makes no
        warranties, expressed or implied, and hereby disclaims and negates all
        other warranties including, without limitation, implied warranties or
        conditions of merchantability, fitness for a particular purpose, or
        non-infringement of intellectual property or other violation of rights.
      </p>
      <p className="mt-3">
        Further, Moneydhan Investment Advisor does not warrant or make any
        representations concerning the accuracy, likely results, or reliability
        of the use of the materials on its website or otherwise relating to such
        materials or on any sites linked to this site.
      </p>
      <p className="mt-3">
        <span className="fw-bold">4. Limitations</span>
        <br /> In no event shall Moneydhan Investment Advisor or its suppliers
        be liable for any damages (including, without limitation, damages for
        loss of data or profit, or due to business interruption) arising out of
        the use or inability to use the materials on Moneydhan Investment
        Advisor’s website, even if Moneydhan Investment Advisor or a Moneydhan
        Investment Advisor authorized representative has been notified orally or
        in writing of the possibility of such damage. Because some jurisdictions
        do not allow limitations on implied warranties, or limitations of
        liability for consequential or incidental damages, these limitations may
        not apply to you.
      </p>
      <p className="mt-3">
        <span className="fw-bold">5. Accuracy of materials</span>
        <br /> The materials appearing on Moneydhan Investment Advisor’s website
        could include technical, typographical, or photographic errors.
        Moneydhan Investment Advisor does not warrant that any of the materials
        on its website are accurate, complete or current. Moneydhan Investment
        Advisor may make changes to the materials contained on its website at
        any time without notice. However Moneydhan Investment Advisor does not
        make any commitment to update the materials.
      </p>
      <p className="mt-3">
        <span className="fw-bold">Links</span>
        <br />
        Moneydhan Investment Advisor has not reviewed all of the sites linked to
        its website and is not responsible for the contents of any such linked
        site. The inclusion of any link does not imply endorsement by Moneydhan
        Investment Advisor of the site. Use of any such linked website is at the
        user’s own risk.
      </p>
      <p className="mt-3">
        <span className="fw-bold">7. Modifications</span>
        <br /> Moneydhan Investment Advisor has not reviewed all of the sites
        linked to its website and is not responsible for the contents of any
        such linked site. The inclusion of any link does not imply endorsement
        by Moneydhan Investment Advisor of the site. Use of any such linked
        website is at the user’s own risk.
      </p>
      <p className="mt-3">
        <span className="fw-bold">8. Governing Law</span>
        <br /> These terms and conditions are governed by and construed in
        accordance with the laws of Surat, Gujarat, India and you irrevocably
        submit to the exclusive jurisdiction of the courts in that State or
        location.
      </p>
      <p className="mt-3">
        <span className="fw-bold">9. Cancellation & Refund Policy</span>
        <br /> User can cancel any of our services/products within 3 days of
        payment and get a full refund. After 3 days we do not provide any refund
        for our services/products.
      </p>
      <p className="mt-3">
        <span className="fw-bold">Privacy Policy</span>
        <br /> Your privacy is important to us. It is Moneydhan Investment
        Advisor’s policy to respect your privacy regarding any information we
        may collect from you across our website,{" "}
        <a href="/https://www.Moneydhan.com" target="_blank">
          https://www.Moneydhan.com
        </a>
        , and other sites we own and operate.
        <br />
        We only ask for personal information when we truly need it to provide a
        service to you. We collect it by fair and lawful means, with your
        knowledge and consent. We also let you know why we’re collecting it and
        how it will be used.
        <br />
        We only retain collected information for as long as necessary to provide
        you with your requested service. What data we store, we’ll protect
        within commercially acceptable means to prevent loss and theft, as well
        as unauthorised access, disclosure, copying, use or modification.
        <br />
        We don’t share any personally identifying information publicly or with
        third-parties, except when required to by law.
        <br />
        Our website may link to external sites that are not operated by us.
        Please be aware that we have no control over the content and practices
        of these sites, and cannot accept responsibility or liability for their
        respective privacy policies.
        <br />
        You are free to refuse our request for your personal information, with
        the understanding that we may be unable to provide you with some of your
        desired services.
        <br />
        Your continued use of our website will be regarded as acceptance of our
        practices around privacy and personal information. If you have any
        questions about how we handle user data and personal information, feel
        free to contact us.
      </p>
      <p className="mt-3">This policy is effective as of 1 Oct 2022.</p>
    </div>
  );
};

export default TermsAndCondition;
