import "./deleteModal.css";

const DeleteModal = ({ open, onClose, onDelete }) => {
  if (!open.open) return null;
  return (
    <div onClick={onClose} className="overlay">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading border-bottom p-2">Delete Client</h4>
        <div className="content">
          <p>Are you sure you want to permanently delete this client?</p>
        </div>
        <div className="modalFooter border-top p-2">
          <button
            onClick={onClose}
            className="btn modal-btn btn-secondary mx-2"
          >
            Close
          </button>
          <button
            onClick={() => onDelete(open.userId)}
            className="btn modal-btn btn-danger mx-2"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
