import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "../../../store/slices/UserSlice";
import loginImg from "../../../assets/login-1.png";
import "../signup-page/registration.css";

const Login = () => {
  const [loginemail, setLoginemail] = useState("");
  const [loginpass, setLoginpass] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => {
    return state.users.data;
  });

  const loginApi = `${process.env.REACT_APP_URL}login`;
  const forgotPasswordApi = `${process.env.REACT_APP_URL}forget-password`;

  useEffect(() => {
    if (userData.token.length > 0) {
      switch (userData.step) {
        case 1:
          navigate("/risk-profile");
          break;
        case 2:
          navigate("/risk-result");
          break;
        case 3:
          navigate("/suitability-assessment");
          break;
        case 4:
          navigate("/e-sign");
          break;
        // case 4:
        //   navigate("/fee-payment");
        //   break;
        default:
          navigate("/user-dashboard");
          break;
      }
    }
  }, []);

  const login = async () => {
    try {
      const res = await fetch(loginApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          email: loginemail,
          password: loginpass,
        }),
      });
      var data = await res.json();
      console.log(data);
    } catch (error) {
      console.log("Sorry, something went wrong " + error.message);
      document.getElementById("loginErrorText").classList.add("d-none");
      document.getElementById("errorText").classList.remove("d-none");
    }
    if (data.status === 200) {
      dispatch(
        addUser({ token: data.token, role: data.role, step: data.step })
      );
      if (data.role !== 0) {
        navigate("/admin-dashboard");
      }
      switch (data.step) {
        case 1:
          navigate("/risk-profile");
          break;
        case 2:
          navigate("/risk-result");
          break;
        case 3:
          navigate("/suitability-assessment");
          break;
        case 4:
          navigate("/e-sign");
          break;
        default:
          navigate("/user-dashboard");
          break;
      }
    } else {
      document.getElementById("loginErrorText").classList.remove("d-none");
      document.getElementById("errorText").classList.add("d-none");
    }
  };
  const forgotPasswordApiCall = async () => {
    try {
      var res = await fetch(forgotPasswordApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          email: resetEmail,
        }),
      });
      var data = await res.json();
      console.log(data);
    } catch (error) {
      console.log(error);
    }
    if (data.status === 200) {
      document.getElementById("reset-container").classList.add("d-none");
      document.getElementById("confirm-container").classList.remove("d-none");
    } else {
      document.getElementById("wrongEmail").classList.remove("d-none");
    }
  };

  const forgotPassword = () => {
    document.getElementById("loginForm").classList.add("d-none");
    document.getElementById("forgotPasswordForm").classList.remove("d-none");
  };

  const backToLogin = () => {
    document.getElementById("loginForm").classList.remove("d-none");
    document.getElementById("forgotPasswordForm").classList.add("d-none");
  };
  return (
    <div className="container-fluid login-container p-0">
      <div className="row m-0 gx-0 h-100">
        <div className="col-12 col-md-6">
          <div className="left-container">
            <div className="logo text-center text-md-start">
              <img
                src="https://moneydhan.com/wp-content/uploads/2021/02/Moneydhan-Logo-04-1.png"
                alt="moneydhan-logo"
              />
            </div>
            <div className="d-none d-md-block text-center">
              <img
                src={loginImg}
                alt="login-hero-img"
                className="cstm-img-width"
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6" id="loginForm">
          <div
            className="right-container order-2 h-100 d-flex flex-column justify-content-center align-items-center"
            style={{ backgroundColor: "#3861c0" }}
          >
            <div className="form-container bg-white p-2 p-sm-3 p-lg-5">
              <div>
                <p className="text-danger small d-none" id="loginErrorText">
                  Incorrect email or passowrd. Please try again.
                </p>
                <p className="text-danger small d-none" id="errorText">
                  Sorry something went wrong our side. Please try again.
                </p>
                <div className="form-group">
                  <label htmlFor="loginemail" className="mb-0">
                    Email*
                  </label>
                  <div className="d-flex align-items-center position-relative border border-1 input-area">
                    <input
                      type="email"
                      name="loginemail"
                      className="form-control border-0 input-field bg-transparent"
                      id="loginemail"
                      placeholder="ex: john@gmail.com"
                      value={loginemail}
                      onChange={(e) => setLoginemail(e.target.value)}
                      required
                    />
                    <i className="bi bi-envelope-fill position-absolute fs-5 email-icon text-secondary"></i>
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="loginpassword" className="mb-0">
                    Password*{" "}
                  </label>
                  <p className="mb-0 fw-normal small text-success">
                    ( For the first time sign up, we need to create a password )
                  </p>
                  <div className="d-flex align-items-center position-relative border border-1 input-area">
                    <input
                      type="password"
                      name="loginpassword"
                      className="form-control border-0 input-field bg-transparent"
                      id="loginpassword"
                      placeholder="Enter your password"
                      value={loginpass}
                      onChange={(e) => setLoginpass(e.target.value)}
                      required
                    />
                    <i className="bi bi-lock-fill position-absolute fs-5 email-icon text-secondary"></i>
                  </div>
                  <span
                    className="text-primary small text-decoration-none mt-1"
                    onClick={forgotPassword}
                    style={{ cursor: "pointer" }}
                  >
                    Forgot Password?
                  </span>
                </div>
                <div className="mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary w-100 text-decoration-none"
                    id="login-btn"
                    value="Login"
                    onClick={login}
                  >
                    Login
                  </button>
                </div>
              </div>
              <p className="text-center mt-3 login-text">
                Don't Have an account ?
                <Link
                  className="text-primary ms-1 text-decoration-none fw-bold"
                  to="/signup"
                >
                  Signup Now
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 d-none" id="forgotPasswordForm">
          <div
            className="right-container order-2 h-100 d-flex flex-column justify-content-center align-items-center"
            style={{ backgroundColor: "#3861c0" }}
          >
            <div className="form-container bg-white p-5">
              <div className="reset-container" id="reset-container">
                <h3 className="pb-3">Find your account</h3>
                <div className="form-group">
                  <label htmlFor="resetEmail" className="cstm-label">
                    Enter your registered email id to request for a password
                    reset.
                  </label>
                  <div className="d-flex align-items-center position-relative border border-1 input-area">
                    <input
                      type="email"
                      name="resetEmail"
                      id="resetEmail"
                      className="form-control border-0 input-field bg-transparent"
                      placeholder="ex: john@gmail.com"
                      value={resetEmail}
                      onChange={(e) => setResetEmail(e.target.value)}
                      required
                    />
                    <i className="bi bi-envelope-fill position-absolute fs-5 email-icon text-secondary"></i>
                  </div>
                </div>
                <small className="text-danger mt-1 d-none" id="wrongEmail">
                  Incorrect, please enter registered email only.
                </small>
                <input
                  type="submit"
                  className="btn btn-primary mt-3 w-100"
                  value="Reset Password"
                  onClick={forgotPasswordApiCall}
                />
                <p className="text-center mt-3">
                  Go back to
                  <span
                    className="text-primary ms-1"
                    style={{ cursor: "pointer" }}
                    onClick={backToLogin}
                  >
                    Login
                  </span>
                </p>
              </div>
              <div className="confirm-container d-none" id="confirm-container">
                <p>
                  A link has been sent to the provided email id for resetting
                  the password.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
