import React from "react";

const Footer = () => {
  return (
    <div className="text-center bg-white py-2">
      © All rights reserved. | Developed by{" "}
      <a
        href="https://skilldukan.in/"
        target="_blank"
        rel="noreferrer"
        className="text-primary"
      >
        SkillDukan
      </a>
    </div>
  );
};

export default Footer;
