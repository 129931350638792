import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../layout/Navbar";
import ProgressBar from "../../ui/progressBar/ProgressBar";
import PieChart from "../../ui/PieChart";
import Footer from "../../layout/Footer";
import { updateStep } from "../../../store/slices/UserSlice";

const RiskResult = () => {
  const userQandAapi = `${process.env.REACT_APP_URL}user-question-answers`;
  const [riskData, setRiskData] = useState([]);
  const [riskDetail, setRiskDetail] = useState({
    score: 0,
    level: "",
  });

  const userData = useSelector((state) => {
    return state.users.data;
  });
  const ref = useRef(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const riskDetailsApi = useCallback(async () => {
    try {
      const res = await fetch(userQandAapi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          token: userData.token,
        }),
      });
      var data = await res.json();
    } catch (error) {
      console.log(error.message);
    }
    if (data.status === 200) {
      setRiskData(data.data);
      setRiskDetail({ score: data.score, level: data.riskLevel });
      dispatch(updateStep({ ...userData, step: 2 }));
    }
  }, [userData, userQandAapi, dispatch]);

  useEffect(() => {
    if (ref.current) {
      if (!userData.token) {
        navigate("/");
      }
      riskDetailsApi();
    }
    return () => {
      ref.current = false;
    };
  }, [riskDetailsApi, navigate, userData]);

  return (
    <section className="result-page">
      <Navbar />
      <ProgressBar activestep="1" />
      <h4 className="mb-0 pt-5 ps-2">Your risk score result!</h4>
      <div className="row mx-0 py-2">
        <div className="col-12 col-md-7">
          <table className="table table-bordered table-hover table-striped">
            <thead>
              <tr>
                <th scope="col" className="w-50">
                  Question
                </th>
                <th scope="col">Score</th>
              </tr>
            </thead>
            <tbody>
              {riskData.length > 0 ? (
                riskData.map((data) => (
                  <tr key={data[0]}>
                    <td>{data[0]}</td>
                    <td>{`${data[1]} - ( ${data[2]} score)`}</td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan={2}>No data found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="col-12 col-md-5" style={{ height: "400px" }}>
          <PieChart riskDetail={riskDetail} />
        </div>
      </div>
      <Link to="/suitability-assessment" className="btn btn-lg btn-primary m-3">
        Next
      </Link>
      <Link to="/risk-profile" className="btn btn-lg btn-primary m-3">
        Edit Risk Score
      </Link>
      <Footer />
    </section>
  );
};

export default RiskResult;
