import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addUser } from "../../../store/slices/UserSlice";
import "../signup-page/registration.css";

const AdminLogin = () => {
  const loginApi = `${process.env.REACT_APP_URL}login`;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loginemail, setLoginemail] = useState("");
  const [loginpass, setLoginpass] = useState("");

  const login = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(loginApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          email: loginemail,
          password: loginpass,
        }),
      });
      var data = await res.json();
    } catch (error) {
      console.log("Sorry, something went wrong " + error.message);
      document.getElementById("loginErrorText").classList.add("d-none");
      document.getElementById("errorText").classList.remove("d-none");
    }
    if (data.status === 200) {
      dispatch(
        addUser({ token: data.token, role: data.role, step: data.step })
      );
      if (data.role === 0) {
        navigate("/risk-profile");
      } else {
        navigate("/admin-dashboard");
      }
    } else {
      document.getElementById("loginErrorText").classList.remove("d-none");
      document.getElementById("errorText").classList.add("d-none");
    }
  };

  return (
    <section className="admin-login-container d-flex align-items-center justify-content-center">
      <div className="container bg-white rounded-3 admin-form-container">
        <form className="p-3" onSubmit={login}>
          <p className="text-danger small d-none" id="loginErrorText">
            Incorrect email or passowrd. Please try again.
          </p>
          <p className="text-danger small d-none" id="errorText">
            Sorry something went wrong our side. Please try again.
          </p>
          <div className="mb-2">
            <label htmlFor="admin-email" className="form-label mb-0">
              Email address*
            </label>
            <div className="d-flex align-items-center position-relative border border-1 input-area">
              <input
                type="email"
                className="form-control border-0 input-field bg-transparent"
                id="admin-email"
                name="admin-email"
                aria-describedby="emailHelp"
                value={loginemail}
                onChange={(e) => setLoginemail(e.target.value)}
                required
              />
              <i className="bi bi-envelope-fill position-absolute fs-5 email-icon text-secondary"></i>
            </div>
          </div>
          <div className="mb-2">
            <label htmlFor="admin-pass" className="form-label mb-0">
              Password*
            </label>
            <div className="d-flex align-items-center position-relative border border-1 input-area">
              <input
                type="password"
                className="form-control border-0 input-field bg-transparent"
                id="admin-pass"
                name="admin-pass"
                value={loginpass}
                onChange={(e) => setLoginpass(e.target.value)}
                required
              />
              <i className="bi bi-lock-fill position-absolute fs-5 email-icon text-secondary"></i>
            </div>
          </div>
          <button type="submit" className="btn btn-primary mt-3 w-100">
            Submit
          </button>
        </form>
      </div>
    </section>
  );
};

export default AdminLogin;
