import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import "../signup-page/registration.css";

const ResetPassword = () => {
  const resetPasswordApi = `${process.env.REACT_APP_URL}reset-password`;

  const [newPassword, setNewPassword] = useState("");
  const { token } = useParams();

  const resetPasswordApiCall = async () => {
    try {
      var res = await fetch(resetPasswordApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          token: token,
          password: newPassword,
        }),
      });
      var data = await res.json();
    } catch (error) {
      alert("error" + error.message);
    }
    if (data.status === 200) {
      document.getElementById("resetForm").classList.add("d-none");
      document.getElementById("resetConfirmation").classList.remove("d-none");
    } else {
      document.getElementById("resetPassError").classList.remove("d-none");
    }
  };

  return (
    <div className="resetPasswordContainer d-flex justify-content-center align-items-center">
      <div className="card shadow p-5 cstm-width" id="resetForm">
        <h3>Reset your password here!</h3>
        <div className="form-group mt-3">
          <div className="d-flex align-items-center position-relative border border-1 input-area">
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              className="form-control border-0 input-field bg-transparent"
              placeholder="Set new password..."
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <i className="bi bi-lock-fill position-absolute fs-5 email-icon text-secondary"></i>
          </div>
        </div>
        <small className="text-danger mt-1 d-none" id="resetPassError">
          Sorry, something went wrong. Please try again.
        </small>
        <div className="form-group mt-3 d-none">
          <div className="d-flex align-items-center position-relative border border-1 input-area">
            <input
              type="text"
              id="token"
              name="token"
              className="form-control border-0 input-field bg-transparent"
              value={token}
              required
              hidden
            />
          </div>
        </div>
        <button className="btn btn-primary mt-3" onClick={resetPasswordApiCall}>
          Reset Password
        </button>
      </div>
      <div
        className="card shadow p-5 text-center d-none cstm-width"
        id="resetConfirmation"
      >
        <h5>Your password has been successfuly reset.</h5>
        <Link to="/login" className="btn btn-primary mt-2 w-25 m-auto">
          Login Now
        </Link>
      </div>
    </div>
  );
};

export default ResetPassword;
