import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: { data: { token: "", role: 0, step: 0 } },
  reducers: {
    addUser(state, action) {
      return { ...state, data: action.payload };
    },
    deleteUser(state, action) {
      return { ...state, data: { token: "", role: 0, step: 0 } };
    },
    updateStep(state, action) {
      return { ...state, data: action.payload };
    },
  },
});

export default userSlice.reducer;
export const { addUser, deleteUser, updateStep } = userSlice.actions;
