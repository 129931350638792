import { ResponsivePie } from "@nivo/pie";

const PieChart = ({ riskDetail }) => {
  const data = [
    {
      id: "Your Risk Score",
      value: riskDetail.score,
    },
    {
      id: "left out score",
      value: 143 - riskDetail.score,
    },
  ];
  return (
    <ResponsivePie
      data={data}
      colors={["#FFB600", "#cecccc"]}
      theme={{
        labels: {
          text: {
            fontSize: "16px",
          },
        },
        legends: {
          text: {
            fontSize: "13px",
          },
        },
      }}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.7}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["brighter", 3]],
      }}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["brighter", 2]],
      }}
      initialHiddenIds={["left out score"]}
      enableArcLinkLabels={false}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: 56,
          itemsSpacing: 35,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: "#999",
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
      layers={["arcs", "arcLabels", "arcLinkLabels", "legends", CenteredMetric]}
    />
  );
};

export default PieChart;

const CenteredMetric = ({ dataWithArc, centerX }) => {
  let total = dataWithArc[0].data.value;
  return (
    <>
      <text
        x={centerX}
        y={120}
        textAnchor="middle"
        dominantBaseline="central"
        style={{ color: "#FFFFFF", fontSize: "30px" }}
      >
        {total}/143
      </text>
      <text
        x={centerX}
        y={160}
        textAnchor="middle"
        dominantBaseline="central"
        style={{ color: "#FFFFFF", fontSize: "28px" }}
      >
        Risk Score
      </text>
    </>
  );
};
