import React from "react";
import "./ProgressBar.css";

const ProgressBar = (prop) => {
  var activestep = prop.activestep;
  return (
    <section className="container mt-4 mb-5 steps">
      <div className="row">
        <div className="col-4 col-md-4 col-lg-2 mb-5 offset-lg-1">
          <div className={activestep == 1 ? "step active" : "step"}>1</div>
          <p className="progress-title ps-1">Risk Profile</p>
        </div>
        <div className="col-4 col-md-4 col-lg-2 mb-5">
          <div className={activestep == 2 ? "step active" : "step"}>2</div>
          <p className="progress-title">Suitability Assessment</p>
        </div>
        <div className="col-4 col-md-4 col-lg-2 mb-5">
          <div className={activestep == 3 ? "step active" : "step"}>3</div>
          <p className="progress-title ps-3">e-KYC</p>
        </div>
        <div className="col-4 col-md-4 col-lg-2 mb-5">
          <div className={activestep == 4 ? "step active" : "step"}>4</div>
          <p className="progress-title ps-3">e-Sign</p>
        </div>
        <div className="col-4 col-md-4 col-lg-2 mb-5">
          <div className={activestep == 5 ? "step active" : "step"}>5</div>
          <p className="progress-title ps-3">Payment</p>
        </div>
      </div>
    </section>
  );
};

export default ProgressBar;
